<template>
  <div v-if="loadingState === 'ready'" class="container-sm pl-0 pr-0">
    <h1 class="m-0 text-center tw-text-30-bold tw-leading-41">
      {{ $t("temp.maintenance_diary") }}
    </h1>
    <div class="text-center loglist-mt-14 tw-leading-25">
      <div class="tw-text-18 loglist-text-color-446883">
        {{ "ATS Keskus" }}: {{ metaDiary.address }}
      </div>
    </div>
    <LogMessageFlasher />
    <div v-if="log" class="loglist-mt-27">
      <template v-if="isStageList">
        <LogListRowButton plus @click="setStageToEdit">
          {{ "+" }}
        </LogListRowButton>
        <div v-for="(item, i) of entries.data" :key="item.id" class="tw-mt-8">
          <LogListRowButton @click="showEntry(i)">
            <LogListRowInner
                v-if="getType(item.log_type_id).name.default.startsWith(item.dates.created)"
                :textLeft="item.dates.created"
                textRight="Sündmus"
            />
            <LogListRowInner
                v-else
                :textLeft="item.dates.created"
                :textRight="getType(item.log_type_id).name.default"
            />

          </LogListRowButton>
          <div v-if="currentEntry === i" class="mt-4 mb-4">
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between">
                <p class="m-0 tw-text-15-bold">{{ "Kande sisestaja" }}</p>
                <p class="m-0 tw-text-15">
                  {{ item.user.name }}
                </p>
              </div>
              <div class="d-flex justify-content-between tw-mt-18">
                <p class="m-0 tw-text-15-bold">{{ "Töö liik" }}</p>
                <p class="m-0 tw-text-15">
                  {{ getType(item.log_type_id).name.default }}
                </p>
              </div>
              <div class="d-flex justify-content-between tw-mt-18" v-if="item.created_at">
                <p class="m-0 tw-text-15-bold">{{ "Kanne loodud" }}</p>
                <p class="m-0 tw-text-15">
                  {{ item.created_at }}
                </p>
              </div>

              <div class="d-flex justify-content-between tw-mt-18" v-if="item.confirmed_at">
                <p class="m-0 tw-text-15-bold">{{ "Kanne kinnitatud" }}</p>
                <p class="m-0 tw-text-15">
                  {{ item.confirmed_at }}
                </p>
              </div>

              <div class="tw-mt-18 log-list-content">
                <p class="m-0 tw-mb-7 tw-text-15-bold">{{ "Sisu" }}</p>
                <nl2br tag="p" :text="item.entry" />
                <div v-for="(file, responseIndex) in item.files" :key="responseIndex">
                  <a  :href="file.url" v-if="file.url" style="cursor: pointer;">
                    {{ file.file.orig_file_name }}
                  </a>
                </div>

              </div>
              <div class="tw-mt-18 flex">
                <div
                  v-for="(response, responseIndex) in item.form_entries"
                  :key="responseIndex"
                >
                  <div v-if="response.finished">
                    <a
                      :href="`${apiEndpoint}/forms/response/${response.id}/pdf`"
                      target="_blank"
                    >
                      {{ `${generateFormResponseName(response)}` }}
                    </a>
                  </div>
                  <br />
                </div>
              </div>
              <LogListAtsPdfLink
                  v-if="item.ats"
                class="m-0 mt-0 ml-auto p-0"
                :ats="item.ats"
                :span="false"
              />
            </div>
          </div>
        </div>
        <nav class="log-list-footer">
          <LogListSelectLogCount v-model="query.size" />
          <LogListPagination
            :entries="entries"
            @prev="prev"
            @next="next"
            @first="first"
            @last="last"
          />
        </nav>
      </template>
      <template v-if="isStageEdit">
        <div class="d-flex flex-column">
          <LogEditRowText
            textLeft="Objekti nimi"
            :textRight="metaDiary.objectName"
          />
          <LogEditRowText
            textLeft="Aadress"
            :textRight="metaDiary.address"
            class="tw-mt-18"
          />
          <LogEditRowText
              textLeft="Kanne loodud"
              :textRight="entry.created_at ? entry.created_at : entry.created_at"
              class="tw-mt-18"
          />
          <LogEditRowText
              v-if="entry.confirmed_at"
              textLeft="Kanne kinnitatud"
              :textRight="entry.confirmed_at"
              class="tw-mt-18"
          />
          <div class="log-edit-select tw-mt-18" v-if="!authorized">
            <p class="log-edit-select__left tw-text-15">Kande sisestaja kontakt</p>
            <BFormInput
                type="text"
                class="form-response-input"
                name="guest_name"
                v-model="entry.guestName"
            />
          </div>
          <LogEditRowText
              v-if="authorized"
              textLeft="Kande sisestaja"
              :textRight="adminUser.name"
              class="tw-mt-18"
          />
          <div class="log-edit-select tw-mt-18" v-if="authorized">
            <p class="log-edit-select__left tw-text-15">{{ "Töö liik" }}</p>
            <BFormSelect
              class="log-edit-select__right tw-bg-e4e8ef tw-text-15 tw-color-main tw-color-main-placeholder"
              v-model="entry.log_type_id"
              :options="typeOptions"
            >
              <BFormSelectOption :value="null">
                {{ "Vali" }}
              </BFormSelectOption>
            </BFormSelect>
            <LogEditButtonReport
              class="d-none d-md-block"
              @click="store('openAts')"
            />
          </div>
          <LogEditErrors :errors="errors['data.log_type_id']" />
          <div v-if="entry.ats" class="log-list-ats-link">
            <span class="log-list-ats-link__span"></span>
            <a
                v-if="entry.ats.confirmed_at !== null && entry.ats.url"
                :href="entry.ats.url"
                target="_blank"
                class="tw-text-15 tw-color-main tw-leading-20 log-list-ats-link__link"
            >
              {{ entry.ats.name }}
            </a>
            <a v-else class="tw-text-15 tw-color-main tw-leading-20 log-list-ats-link__link" @click="store('openAts')" >{{ entry.ats.name }}</a>
          </div>

          <div
            class="d-flex justify-content-end tw-mt-18" v-if="authorized"
          >
            <LogEditButtonReport class="d-md-none" @click="store('openAts')" />
          </div>
        </div>
        <div class="tw-mt-18">
          <BFormTextarea
            v-model="entry.entry"
            :placeholder="$t('Sisu')"
            class="tw-bg-e4e8ef tw-text-15 tw-color-main tw-color-main-placeholder"
            rows="7"
          />
          <LogEditErrors :errors="errors['data.entry']" />

          <BFormFile
              class="field-drag-and-drop--mobile"
              name="image"
              v-model="entry.imagePreview"
              data-upload="true"
              accept="image/*"
              style="display: none"
              :capture="true"
              :plain="true"
              @change="onFileChange"
              id="image"
          />
          <label
              for="image"
              class="tw-text-15-bold tw-color-main tw-log-border-d4d9e2 hover:bg-sky-700 buttons__button mt-4"
              style="
                background-color: #fbfbfb;
                border-radius: 4px !important;
                padding: 15px 34px !important;
                cursor: pointer;
              "
          >
            {{ $t("common.takePicture") }}
          </label>
          <div v-if="entry.files">
            <div  v-for="(file, responseIndex) in entry.files" :key="responseIndex">
              <a :href="file.url" v-if="file.url" style="cursor: pointer;">
                {{ file.file.orig_file_name }}
              </a>
            </div>
          </div>

          <img :src="entry.imagePreview" v-if="entry.imagePreview" alt="" class="img-thumbnail ml-4 mt-4" style="max-width: 144px;">
        </div>
        <div class="tw-mt-18 d-flex" v-if="authorized">
          <BFormSelect
            class="log-edit-select__right tw-bg-e4e8ef tw-text-15 tw-color-main tw-color-main-placeholder"
            :options="optionsFormsFilterd"
            v-model="formsOptionsSelected"
            disabled-field="notEnabled"
          >
          </BFormSelect>

          <button
            class="btn tw-button tw-text-15-bold btn-primary log-edit-button-report"
            @click="store('openForm')"
            :disabled="formsOptionsSelected === null"
          >
            {{ $t("log.btn.openForm") }}
          </button>
        </div>
        <div class="tw-mt-18 flex">
          <div
            v-for="(response, responseIndex) in entry.form_entries"
            :key="responseIndex"
          >
            <div v-if="response.finished">
              <a
                :href="`${apiEndpoint}/forms/response/${response.id}/pdf`"
                target="_blank"
                class="tw-text-15 tw-color-main tw-leading-20 log-list-ats-link__link"
              >
                {{ `${generateFormResponseName(response)}` }}
              </a>
            </div>
            <div v-else-if="entry">
              <a
                href="#"
                @click.prevent="openForm('form', response.form_id, response.id)"
                class="tw-text-15 tw-color-main tw-leading-20 log-list-ats-link__link"
              >
                {{
                  `${generateFormResponseName(response)} - ${$t(
                    "troubleshooting.unfinished"
                  )}`
                }}
              </a>
            </div>
            <br />
          </div>
        </div>
        <div class="log-edit-footer">
          <div
            class="log-edit-footer__container log-edit-footer__container--left"
          >
            <LogEditButton btnClass="back" @click="setStageToList" :disabled="false">
              {{ $t("common.back") }}
            </LogEditButton>
          </div>
          <div
            class="log-edit-footer__container log-edit-footer__container--right"
          >
            <LogEditButton btnClass="danger" @click="cancel" :disabled="false">
              {{ "Katkesta" }}
            </LogEditButton>
            <LogEditButton btnClass="success" @click="store" :disabled="false" v-if="authorized">
              {{ "Salvesta" }}
            </LogEditButton>
            <LogEditButton
              btnClass="primary"
              @click="confirm"
              :disabled="!canConfirmEntry"
            >
              {{ "Kinnita" }}
            </LogEditButton>
          </div>
        </div>
      </template>
    </div>
    <LogListButtonBack v-if="isStageList" :tenantId="tenantId" :uuid="uuid" />
    <BModal
      ref="atsModal"
      v-model="modals.ats"
      centered
      hide-header
      hide-footer
      modal-class="log-list-modal-ats__modal"
      content-class="log-list-modal-ats__modal-content"
    >
      <div class="log-list-modal-ats">
        <div class="log-list-modal-ats__left">
          <p class="log-list-modal-ats__header">Kinnitatud</p>
          <p class="log-list-modal-ats__text">
            Hoolduse akt on edukalt kinnitatud!
          </p>
          <button class="log-list-modal-ats__button" @click="clickModalAts">
            OK
          </button>
        </div>
        <div class="log-list-modal-ats__right"></div>
      </div>
    </BModal>
  </div>
  <AppLoader v-else />
</template>

<script>
import "@/scss/theme-default.scss";
import "@/scss/tw/tw.scss";

import connectionLog from "@/api/modules/log.js";
import productApi from "@/api/modules/product.js";
import AppLoader from "@/components/AppLoader.vue";
import LogEditButton from "@/components/LogEditButton.vue";
import LogEditButtonReport from "@/components/LogEditButtonReport.vue";
import LogEditErrors from "@/components/LogEditErrors.vue";
import LogEditRowText from "@/components/LogEditRowText.vue";
import LogListAtsPdfLink from "@/components/LogListAtsPdfLink.vue";
import LogListButtonBack from "@/components/LogListButtonBack.vue";
import LogListPagination from "@/components/LogListPagination.vue";
import LogListRowButton from "@/components/LogListRowButton.vue";
import LogListRowInner from "@/components/LogListRowInner.vue";
import LogListSelectLogCount from "@/components/LogListSelectLogCount.vue";
import LogMessageFlasher from "@/components/LogMessageFlasher.vue";
import { jLang } from "@/filters/filters.js";
import { utilityProductMeta } from "@/utils/utility.js";
import {
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormInput,
  BModal,
} from "bootstrap-vue";
import { get, has, pick } from "lodash-es";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormInput,
    BModal,
    LogListButtonBack,
    LogEditErrors,
    LogEditRowText,
    LogListRowInner,
    LogMessageFlasher,
    LogEditButton,
    LogListRowButton,
    LogListSelectLogCount,
    LogListPagination,
    AppLoader,
    LogListAtsPdfLink,
    LogEditButtonReport,
  },

  data() {
    return {
      loadingState: "init",
      product: null,
      meta: undefined,
      log: null,
      types: [],
      entries: {
        data: [],
      },
      stage: "list",
      entry: this.getEntry(),
      errors: this.getErrors(),
      show: null,
      currentEntry: null,
      size: 5, // must be same as in query
      query: {
        page: 1,
        size: 5,
      },
      logEntryAts: undefined,
      modals: {
        ats: false,
      },
      isLogTypeGroupAts: false,
      canConfirmEntry: false,
      optionsForms: [],
      formsOptionsSelected: null,
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,

    };
  },

  computed: {
    ...mapState("i18n", ["currentLocale"]),
    ...mapGetters(["authorized", "adminUser"]),
    metaDiary() {
      const defaultMetaDiary = {
        address: "Aadress",
        objectName: "Objekti nimi",
        contact: "Kontakt",
      };

      if (!this.meta || this.meta.length === 0) {
        return defaultMetaDiary;
      }

      const diary = utilityProductMeta.selectGroup(this.meta, "Hoolduspäevik");

      if (diary === undefined) {
        return defaultMetaDiary;
      }

      const address = utilityProductMeta.selectMeta(diary, "Aadress");
      const objectName = utilityProductMeta.selectMeta(diary, "Objekti nimi");
      const contact = utilityProductMeta.selectMeta(diary, "Kontakt");

      return {
        address: address ? address.value : "Aadress",
        objectName: objectName ? objectName.value : "Objekti nimi",
        contact: contact ? contact.value : "Kontakt",
      };
    },

    date() {
      return this.formatDate(new Date());
    },

    uuid() {
      return this.$route.params.uuid;
    },

    tenantId() {
      return this.$route.params.tenantId;
    },

    isStageList() {
      return this.stage === "list";
    },

    isStageEdit() {
      return this.stage === "edit";
    },

    typeOptions() {
      const options = [];

      for (const type of this.types) {
        if (type.visible || type.id === this.entry.log_type_id) {
          options.push({
            value: type.id,
            text: type.name.default,
          });
        }
      }

      return options;
    },

    optionsFormsFilterd() {
      const formOptions = this.optionsForms
        .filter((option) => {
          const langAllowed =
            option.show_only_langs === null ||
            option.show_only_langs.includes(this.currentLocale);

          return option.is_public && langAllowed;
        })
        .map((form) => {
          return { value: form.id, text: jLang(form.name) };
        });

      const defaultOption = [{ value: null, text: "Vali", notEnabled: true }];

      return defaultOption.concat(formOptions);
    },
  },

  methods: {
    openImage: function (data) {
      let w = window.open('about:blank');
      let image = new Image();
      image.src = data;
      setTimeout(function(){
        w.document.write(image.outerHTML);
      }, 0);
    },
    onFileChange: function (event) {
      const file = event.target.files[0]
      if (!file) {
        return false
      }
      if (!file.type.match('image.*')) {
        return false
      }


      const reader = new FileReader()
      const that = this
      reader.onload = function (e) {
        const maxAllowedSize = 5 * 1024 * 1024;
        if (file.size > maxAllowedSize) {
          e.target.value = ''
          alert('Fail liiga suur, max 5MB');
          return;
        }
        that.entry.imagePreview = e.target.result
        that.entry.imageName = file.name;
      }
      reader.readAsDataURL(file)
    },
    formFileUploadMobile(file) {

      this.sendForm([file],false).then(() => location.reload());
    },

    prev() {
      if (this.entries.current_page > 1) {
        this.query.page--;
      }
    },

    next() {
      if (this.entries.current_page < this.entries.last_page) {
        this.query.page++;
      }
    },

    first() {
      this.query.page = 1;
    },

    last() {
      this.query.page = this.entries.last_page;
    },

    getType(id) {
      for (const type of this.types) {
        if (type.id === id) {
          return type;
        }
      }

      return null;
    },

    showEntry(i) {
      const entry = this.entries.data[i];

      if (entry.status === "saved") {
        this.stage = "edit";
        this.entry = entry;
        this.currentEntry = null;
        this.$store.commit("rootSetSelectedEntryId", this.entry.id);
      } else if (this.currentEntry === i) {
        this.currentEntry = null;
      } else {
        this.currentEntry = i;
      }
    },

    cancel() {
      const id = this.entry.id;

      this.stage = "list";
      this.currentEntry = null;
      this.entry = this.getEntry();
      this.errors = this.getErrors();

      if (id === null) {
        this.$root.$emit("message.flash", "warning", "Kannet ei salvestatud.");
      }

      this.$store.commit("rootSetSelectedEntryId");
    },

    formatDate(date) {
      return date.getDate() + "." + date.getMonth() + "." + date.getFullYear();
    },

    formatDateTime(date) {
      let hours = date.getHours();
      if (hours < 10) {
        hours = "0" + hours;
      }

      let minutes = date.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      let seconds = date.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
    },

    formatDate2(date) {
      return date.getDate() + "_" + date.getMonth() + "_" + date.getFullYear();
    },

    generateFormResponseName(response) {
      let string = `${response.form.name.en}_${response.created_at}.pdf`;

      string.replaceAll(" ", "_");

      return string.toLowerCase();
    },

    async fetchProduct() {
      const tenantId = this.$route.params.tenantId;
      const uuid = this.$route.params.uuid;

      try {
        const responseProduct = await productApi.getProduct({ tenantId, uuid });
        this.product = responseProduct.data.data.product;
        this.meta = responseProduct.data.data.meta;

        const responseLog = await connectionLog.getPugLog(this.product.id);
        this.log = responseLog.data.data;

        const responsePugLogType = await connectionLog.getPugLogType(
          this.log.id
        );
        this.types = responsePugLogType.data.data;

        if (this.log) {
          await this.fetchEntries();
        }

        const responseForm = await connectionLog.getForms(responseProduct.data.data.tenant_id);
        const forms = get(responseForm, "data", []);

        this.optionsForms = forms.filter((form) => form.is_public);

        const selectedEntryId = this.$store.state.logListState.selectedEntryId;
        if (selectedEntryId) {
          const selectedEntryIndex = this.entries.data.findIndex(
            (entry) => entry.id === selectedEntryId
          );
          if (selectedEntryIndex !== -1) {
            this.showEntry(selectedEntryIndex);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async fetchEntries() {
      const responsePugLogType = await connectionLog.getPugLogType(
          this.log.id
      );
      this.types = responsePugLogType.data.data;

      this.entries = await connectionLog.getPugLogEntry(
        this.log.id,
        this.query
      );


    },

    async store(option = "") {
      this.errors = this.getErrors();
      try {
        const res = await this.saveEntry();
        await this.fetchEntries();

        const entry = res.data.data;
        this.stage = "edit";
        this.entry = entry;
        this.$root.$emit("message.flash", "success", "Kanne salvestatud.");

        if (option === "openAts") {
          this.openForm("ats");
        }

        if (option === "openForm") {
          this.openForm("form", entry.id);
        }
      } catch (err) {
        console.error(err);
        this.errors = err.errors;

        this.$root.$emit(
          "message.flash",
          "danger",
          "Salvestamise esines vigu."
        );
      }
    },

    async saveEntry(checkFormEntries = false) {
      this.errors = this.getErrors();

      let save;

      if (this.entry.id === null) {
        this.entry.status = "saved";
        save = connectionLog.storeLogEntry(this.entry, this.log.id);
      } else {
        if (checkFormEntries) {
          if (this.entry.form_entries) {
            const isEveryFormEntryFinished = this.entry.form_entries.every(
                (response) => response.finished
            );
            if (!isEveryFormEntryFinished) {
              throw new Error("found_unfinished_form_entries");
            }
          }

        }

        save = connectionLog.storeLogEntry(
          this.entry,
          this.log.id,
          this.entry.id,

        );
      }
      await this.fetchEntries();

      const responsePugLogType = await connectionLog.getPugLogType(
          this.log.id
      );
      this.types = responsePugLogType.data.data;

      return save;
    },

    confirm() {
      this.saveEntry(true)
        .then((res) => {
          this.entry = res.data.data;
          return connectionLog.confirmLogEntry(
            this.entry,
            this.log.id,
            this.entry.id

          );
        })
        .then(this.fetchEntries)
        .then(() => {
          this.currentEntry = null;
          this.entry = this.getEntry();
          this.stage = "list";

          this.$store.commit("rootSetSelectedEntryId");

          this.$root.$emit("message.flash", "success", "Kanne kinnitatud.");
        })
        .catch((err) => {
          if (err.message === "found_unfinished_form_entries") {
            this.$root.$emit(
              "message.flash",
              "danger",
              "Kinnitamisel tuvastati lõpetamata vorme"
            );

            window.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            console.log('err',err);
            console.log('this.errors', this.errors);
            this.errors = err.errors;

            this.$root.$emit(
              "message.flash",
              "danger",
              "Kinnitamisel esines vigu."
            );
          }
        });
    },

    getErrors() {
      return {
        "data.log_type_id": [],
        "data.entry": [],
      };
    },

    getEntry() {
      const date = new Date();

      return {
        id: null,
        log_type_id: null,
        entry: null,
        status: null,
        guestName: "",
        image: null,
        imagePreview: '',
        imageName: '',
        created_at: this.formatDateTime(date),
        confirmed_at: null,
        files: null,

        dates: {
          created: this.formatDate(date),
          updated: this.formatDate(date),
        },
      };
    },

    setStageToEdit() {
      this.stage = "edit";
      this.entry = this.getEntry();
    },

    setStageToList() {
      this.stage = "list";
      this.$store.commit("rootSetSelectedEntryId");
    },

    clickModalAts() {
      this.$store.commit("atsForm/updateValidationStatus", "not_validated");
      this.modals.ats = false;
    },

    async openForm(type, formId = null, responseId = null) {
      if (type === "ats") {
        const logTypeObject = this.types.find(
          (type) => type.id === this.entry.log_type_id
        );

        this.$router.push({
          name: `AtsForm${logTypeObject.meta.kind.toUpperCase()}`,
          params: { logId: this.log.id, entryId: this.entry.id },
        });
      } else if (type === "form") {
        this.$router.push({
          name: `FormResponse`,
          params: {
            tenantId: this.$route.params.tenantId,
            uuid: this.$route.params.uuid,
            formId: this.formsOptionsSelected || formId,
            entryId: this.entry.id,
          },
          query: {
            responseId: responseId,
          },
        });
      }
    },
  },

  watch: {
    query: {
      handler(value) {
        if (value.size !== this.size) {
          this.query.page = 1;
        }

        this.fetchEntries();

        this.currentEntry = null;
        this.size = value.size;
      },
      deep: true,
    },
    "entry.log_type_id": {
      handler(newValue) {
        const typeObject = this.types.find((type) => type.id === newValue);
        this.isLogTypeGroupAts = get(typeObject, "meta.group") === "ats";
      },
    },
    entry: {
      handler(newValue) {
        if (has(newValue, "id") && get(newValue, "status") === "saved" && get(newValue, 'ats.confirmed_at') !== null) {
          if (this.entry.id) {
            connectionLog
                .isValidated(this.log.id, this.entry.id)
                .then((res) => {
                  this.canConfirmEntry = res.data.validated;
                })
                .catch(console.error);
          }

        } else {
          if (!this.authorized) {
            this.canConfirmEntry = this.entry.guestName !== "" && this.entry.entry !== null && this.entry.entry !== '';
          } else {
            this.canConfirmEntry = false;
          }
        }

        const temp = pick(newValue, ["log_type_id", "entry"]);
        if (temp.log_type_id !== null && temp.entry !== null && temp.entry !== '') {
          this.canConfirmEntry = true;
        }
      },
      deep: true,
    },
  },

  async beforeMount() {
    this.loadingState = "idle";
    await this.fetchProduct();
    this.loadingState = "ready";
    this.modals.ats =
      this.$store.state.atsForm.validationStatus === "validated";
  },
};
</script>

<style lang="scss">
.log-list-content > p + p {
  line-height: 26px;
  margin: 0 !important;
}

.log-list-footer {
  display: flex;
  margin-top: 62px !important;
  flex-direction: column-reverse;
  justify-content: stretch;
  gap: 24px;
}

.log-list-footer > * {
  flex: 0 0 20%;
}

.log-edit-row {
  display: flex;
}

.log-edit-select {
  display: flex;
  align-items: center;
}

.log-edit-select__left {
  flex: 0 0 124px;
  margin: 0;
}
.log-edit-select__right {
  flex: 5 0;
}

.log-edit-footer {
  display: flex;
  gap: 30px;
  margin-top: 33px;
  flex-direction: column-reverse;
}

.log-edit-footer__container {
  display: flex;
  gap: 12px;
}

.log-edit-footer__container--left {
  flex: 1 0 25%;
}

.log-edit-footer__container--right {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 75%;
  justify-content: space-between;
}

.log-list-modal-ats {
  display: flex;
  padding: 0px 17px !important;
  justify-content: space-between;
}

.log-list-modal-ats__modal {
  background-color: hsla(0, 0%, 19%, 0.22);
}

.log-list-modal-ats__modal-content {
  border-radius: 4px !important;
}

.log-list-modal-ats__left {
  flex: 1 1 75%;
}

.log-list-modal-ats__header {
  $nunitoSans: "Nunito Sans", sans-serif !important;
  $textColor: #000054 !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  line-height: 49px !important;
}

.log-list-modal-ats__text {
  $nunitoSans: "Nunito Sans", sans-serif !important;
  $textColor: #000054 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 26px !important;
}

.log-list-modal-ats__button {
  $nunitoSans: "Nunito Sans", sans-serif !important;
  $textColor: #000054 !important;
  background-color: inherit;
  border-radius: 4px;
  border: #000054 solid 2px;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  padding: 6px 26px !important;
  text-align: center !important;
}

.log-list-modal-ats__right {
  flex: 0 1 25% !important;
  background: no-repeat center
    url("data:image/svg+xml,%3Csvg width='92' height='92' viewBox='0 0 92 92' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M46 92C20.5942 92 0 71.4058 0 46C0 20.5942 20.5942 0 46 0C71.4058 0 92 20.5942 92 46C92 71.4058 71.4058 92 46 92ZM27.6 36.8L18.4 46L41.4 69L73.6 36.8L64.4 27.6L41.4 50.6L27.6 36.8Z' fill='%2373C088'/%3E%3C/svg%3E%0A");
}

.loglist-text-color-446883 {
  color: #446883;
}

.loglist-text-color-859cad {
  color: #859cad;
}

.loglist-mt-14 {
  margin-top: 14px !important;
}

.loglist-mt-27 {
  margin-top: 27px !important;
}

.loglist-mt-42 {
  margin-top: 42px !important;
}

.loglist-mt-56 {
  margin-top: 56px !important;
}

.loglist-mt-62 {
  margin-top: 62px !important;
}

.log-list-ats-link {
  display: flex;
}

.log-list-ats-link__span {
  display: none;
}

.log-list-ats-link__link {
  border-radius: 4px;
  border: #e4e8ef solid 1px;
  width: 100%;
  padding: 6px 8px !important;
  min-width: 110px;
}

label.tw-text-15-bold.tw-color-main.tw-log-border-d4d9e2.hover\:bg-sky-700.buttons__button.mt-4:hover {
  background-color: rgba(251,251,251,0.5) !important;
  border-color: rgba(212,217,226,0.5) !important;
}
.form-control:focus {
  box-shadow: unset !important;
  border-color: unset !important;
}
@media screen and (min-width: 768px) {
  .log-list-ats-link__span {
    display: block;
    flex: 0 0 124px;
  }

  .log-list-ats-link__link {
    width: min-content;
  }
}

@media screen and (min-width: 768px) {
  .log-list-footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .log-edit-select__right {
    flex: 1;
  }

  .log-edit-footer {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }

  .log-edit-footer__container--right {
    justify-content: flex-end;
  }

  .log-edit-footer__container--left > *,
  .log-edit-footer__container--right > * {
    flex: 0 1 172px;
  }
}
</style>
