<template>
  <div class="ats-form-textarea__container">
    <textarea
      class="tw-text-15 tw-color-main tw-color-main-placeholder ats-form-textarea"
      rows="5"
      :placeholder="placeholder"
      v-model="model"
      :disabled="isConfirmed"
    />
    <AtsFormErrors :errorKey="stateKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    AtsFormErrors,
  },
  props: {
    stateKey: {
      required: true,
    },
    commit: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
    model: {
      get() {
        return this.storeKeyValue(this.commit, this.stateKey);
      },
      set(value) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ats-form-textarea__container {
  display: flex;
  flex-direction: column;
}
.ats-form-textarea {
  background-color: #e4e8ef !important;
  border-radius: 4px !important;
  border: 4px solid #e4e8ef;
  outline-width: 0px !important;
  padding: 10px !important;
  width: 100% !important;
}

/*textarea::-webkit-input-placeholder {
  color: #A8A8BE !important;
}

textarea:-moz-placeholder {
  color: #A8A8BE !important;
}

textarea::-moz-placeholder {
  color: #A8A8BE !important;
}

textarea:-ms-input-placeholder {
  color: #A8A8BE !important;
}

textarea::placeholder {
  color: #A8A8BE !important;
}*/

@media (min-width: 768px) {
  .ats-form-textarea__container,
  .ats-form-textarea {
    align-self: flex-start;
  }
}
</style>
