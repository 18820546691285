<template>
  <button
    v-if="mobile"
    class="tw-text-15-bold ats-form-button-delete ats-form-button-delete--sm"
    @click="$emit('click')"
  >
    {{ "Kustuta" }}
  </button>
  <button
    v-else
    class="tw-text-15-bold ats-form-button-delete ats-form-button-delete--md"
    @click="$emit('click')"
  ></button>
</template>

<script>
export default {
  props: {
    name: Number,
    mobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.ats-form-button-delete {
  align-items: center;
  align-self: flex-end;
  background: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #e4e8ef;
  justify-content: center;
  margin-left: auto;
}

.ats-form-button-delete--sm {
  display: flex;
  max-height: 33px !important;
  min-width: 100px !important;
  padding: 14px;
}

.ats-form-button-delete--md {
  display: none;
}

@media (min-width: 768px) {
  .ats-form-button-delete {
    align-self: flex-start;
    flex: 0 1 32px;
    min-height: 31px;
    min-width: 31px;
  }

  .ats-form-button-delete--sm {
    display: none;
  }

  .ats-form-button-delete--md {
    display: flex;
    margin-top: 8px;
    background: no-repeat center
      url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1.60649L11.3935 0L6.5 4.89351L1.60649 0L0 1.60649L4.89351 6.5L0 11.3935L1.60649 13L6.5 8.10649L11.3935 13L13 11.3935L8.10649 6.5L13 1.60649Z' fill='%23000054'/%3E%3C/svg%3E%0A");
  }
}
</style>
