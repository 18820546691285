import Vue from "vue";
import Router from "vue-router";

import ProductInfo from "@/views/products/ProductInfo.vue";
import LogList from "@/views/LogList.vue";
import AtsForm from "@/views/AtsForm.vue";
import FormResponse from "@/views/FormResponse";

Vue.use(Router);

const routes = [
  {
    path: "*",
    component: { template: '<h2 class="p-4">Whoops! Page not found.</h2>' },
  },
  {
    path: "/",
    name: "Home",
    component: {
      template: "<h1>Itemada.</h1>",
    },
  },
  {
    path: "/info/:tenantId/:uuid",
    name: "ProductInfo",
    component: ProductInfo,
    props: true,
  },
  {
    path: "/log/:tenantId/:uuid",
    name: "LogList",
    component: LogList,
  },
  {
    path: "/log/:tenantId/:uuid/:logId/:entryId/ats/quarterly",
    name: "AtsFormQUARTERLY",
    component: AtsForm,
  },
  {
    path: "/log/:tenantId/:uuid/:logId/:entryId/ats/annual",
    name: "AtsFormANNUAL",
    component: AtsForm,
  },
  {
    path: "/form/:tenantId/:formId/:entryId/:uuid",
    name: "FormResponse",
    component: FormResponse,
  },
];

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (window.gtag === undefined) {
    // add gtag script
    const script = document.createElement("script");
    script.id = "googletagmanager";
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_MEASUREMENT_ID}`;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    // add gtag to window for global access
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    window.gtag("js", new Date());

    window.gtag("config", process.env.VUE_APP_GA_MEASUREMENT_ID, {
      send_page_view: false,
    });
  }

  next();
});

router.afterEach((to) => {
  if (window.gtag) {
    window.gtag("set", "page_path", to.path);
    window.gtag("event", "page_view");
  }
});

export default router;
