<template>
  <div class="loglist-row-inner">

      <span v-if="textLeft && textRight" class="tw-flex-1 text-right">
        {{ textLeft }}
      </span>
      <span v-if="textLeft && textRight" class="tw-flex-1 text-left">
        {{ textRight }}
      </span>
      <span v-if="textLeft && !textRight">
        {{ textLeft }}
      </span>
      <span v-if="!textLeft && textRight">
        {{ textRight }}
      </span>

  </div>
</template>

<script>
export default {
  props: {
    textLeft: String,
    textRight: String,
  },
};
</script>

<style lang="scss" scoped>
.loglist-row-inner {
  align-items: center;
  display: flex;
  gap: 40px;
  justify-content: center;
}
</style>
