<template>
  <div class="log-edit-row-text">
    <p class="tw-text-15 log-edit-row-text__left">
      {{ textLeft }}
    </p>
    <p class="tw-text-15 log-edit-row-text__right">
      {{ textRight }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    textLeft: {
      type: String,
    },
    textRight: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.log-edit-row-text {
  display: flex;
}

.log-edit-row-text__left {
  margin: 0;
  flex: 0 0 124px;
}

.log-edit-row-text__right {
  margin: 0;
  flex: 1;
}
</style>
