<template>
  <div class="ats-form-input-text">
    <textarea
      v-if="textarea"
      :placeholder="placeholder"
      v-model="model"
      class="tw-text-15-bold tw-text-15-bold-placeholder tw-color-main tw-color-main-placeholder ats-form-input-text__input"
      :disabled="isConfirmed"
      rows="2"
    ></textarea>
    <input
      v-else
      :type="type"
      :placeholder="placeholder"
      v-model="model"
      class="tw-text-15-bold tw-text-15-bold-placeholder tw-color-main tw-color-main-placeholder ats-form-input-text__input"
      :disabled="isConfirmed"
    />
    <AtsFormErrors :errorKey="errorKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    AtsFormErrors,
  },
  props: {
    stateKey: {
      required: true,
    },
    commit: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: String,
    useErrorKey: {
      type: String,
      required: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
    model: {
      get() {
        return this.storeKeyValue(this.commit, this.stateKey);
      },
      set(value) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value,
        });
      },
    },
    errorKey() {
      return this.useErrorKey || this.stateKey;
    },
  },
};
</script>

<style lang="scss">
input::-webkit-input-placeholder {
  color: #A8A8BE !important;
}

input:-moz-placeholder { /* Firefox 18- */
  color: #A8A8BE !important;
}

input::-moz-placeholder {  /* Firefox 19+ */
  color: #A8A8BE !important;
}

input:-ms-input-placeholder {
  color: #A8A8BE !important;
}

input::placeholder {
  color: #A8A8BE !important;
}
.ats-form-input-text {
  width: 100%;
}

.ats-form-input-text__input,
.ats-form-input-text__input:focus {
  background-color: inherit !important;
  border: none !important;
  outline-width: 0px !important;
  width: 100%;
}

.ats-form-input-text__input::placeholder {
  font-style: italic;
  opacity: 0.8;
}

.atsats-form-input-text__input::-webkit-outer-spin-button,
.ats-form-input-text__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ats-form-input-text__input[type="number"] {
  -moz-appearance: textfield;
}
</style>
