<template>
  <div>
    <FlatPickr
      v-model="model"
      :placeholder="placeholder"
      :config="config"
      class="tw-text-15-bold tw-text-15-bold-placeholder tw-color-main tw-color-main-placeholder ats-form-input-date"
      :disabled="isConfirmed"
    />
    <AtsFormErrors :errorKey="stateKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/airbnb.css";
import FlatPickr from "vue-flatpickr-component";
import { Estonian } from "flatpickr/dist/l10n/et.js";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    FlatPickr,
    AtsFormErrors,
  },
  props: {
    stateKey: {
      type: String,
      required: true,
    },
    commit: {
      type: String,
      required: true,
    },
    placeholder: String,
  },
  data() {
    return {
      config: {
        minDate: "today",
        wrap: true,
        altInput: true,
        altFormat: "d.m.Y",
        locale: Estonian,
      },
    };
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
    model: {
      get() {
        return this.storeKeyValue(this.commit, this.stateKey);
      },
      set(value) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value,
        });
      },
    },
  },
};
</script>

<style lang="scss">
.ats-form-input-date {
  background-color: inherit !important;
  border: none !important;
  outline-width: 0px !important;
  padding: 0 !important;
}

.ats-form-input-date:focus {
  box-shadow: none !important;
}

.ats-form-input-date::placeholder {
  font-style: italic;
  opacity: 0.8;
}
</style>
