<template>
  <div class="temp">
    <div
      class="ats-form-yesno__checkbox ats-form-yesno__checkbox--left"
      :class="{
        'ats-form-yesno__checkbox--checked': value,
      }"
      @click="$emit('input', true)"
    >
      <input class="hidden" type="radio" :name="name" :value="true" />
      <span>{{ getLabel("yes") }}</span>
    </div>

    <div
      class="ats-form-yesno__checkbox ats-form-yesno__checkbox--right"
      :class="{
        'ats-form-yesno__checkbox--checked':
          value === null || value === undefined ? false : !value,
      }"
      @click="$emit('input', false)"
    >
      <input class="hidden" type="radio" :name="name" :value="false" />
      <span>{{ getLabel("no") }}</span>
    </div>
  </div>
</template>

<script>
import { has } from "lodash-es";

export default {
  props: {
    name: {
      type: String,
    },
    value: {
      type: [String, Boolean],
    },
    options: {
      type: Array,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    getLabel: function (type) {
      if (
        type === "yes" &&
        has(this.options, "[0]") &&
        this.options[0] !== null
      ) {
        return this.options[0];
      } else if (
        type === "no" &&
        has(this.options, "[1]") &&
        this.options[1] !== null
      ) {
        return this.options[1];
      } else if (type === "yes") {
        return this.$t("common.yes");
      } else if (type === "no") {
        return this.$t("common.no");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.temp {
  display: flex;
  width: 47%;
  align-items: stretch;
}

.form-response-grid .temp {
  width: 100%;
}

.ats-form-yesno__checkbox {
  flex: 1;
  min-height: 49px !important;
  max-height: 100% !important;
  align-self: stretch;
}

.ats-form-yesno__checkbox--checked {
  background-color: #000054 !important;
  border-color: #000054 !important;
  color: #fff !important;
}
</style>
