<template>
  <h3 class="m-0 tw-text-15-bold">{{ text }}</h3>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
