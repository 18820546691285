<template>
  <button
    class="btn btn-block btn-lg btn-primary log-list-row-button"
    :class="[plus ? 'tw-text-24' : 'tw-text-15']"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    plus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.log-list-row-button {
  color: white;
  height: 59px !important;
}
</style>
