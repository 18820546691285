<template>
  <div v-if="errors && errors.length" class="tw-mt-18">
    <small v-for="error in errors" :key="error" class="text-danger">
      {{ error }}
    </small>
  </div>
</template>
<script>
export default {
  props: {
    errors: Array,
  },
};
</script>
