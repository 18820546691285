<template>
  <div v-if="hasMessages" class="container-sm">
    <div
      v-for="(message, uid) in this.messages"
      :class="'alert alert-' + message.level"
      role="alert"
      :key="uid"
    >
      {{ message.text }}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="remove(uid)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

export default {
  data() {
    return {
      messages: {},
    };
  },

  computed: {
    hasMessages() {
      return Object.keys(this.messages).length > 0;
    },
  },

  methods: {
    set(level, text) {
      const uid = uuid();

      this.$set(this.messages, uid, {
        level,
        text,
      });

      setTimeout(() => {
        this.remove(uid);
      }, 5000);
    },

    remove(uid) {
      if (this.messages[uid] !== undefined) {
        this.$delete(this.messages, uid);
      }
    },
  },

  mounted() {
    this.$root.$on("message.flash", (level, text) => {
      this.set(level, text);
    });
  },
};
</script>
