<template>
  <router-link
    v-if="back"
    :to="{ name: 'LogList' }"
    class="btn btn-primary tw-text-15-bold tw-color-main ats-form-footer-button ats-form-footer-button--back"
  >
    <slot />
  </router-link>
  <button
    v-else
    class="btn tw-text-15-bold ats-form-footer-button"
    :class="[primary ? 'btn-success' : 'btn-primary']"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ats-form-footer-button {
  align-self: flex-start;
  border-radius: 50px !important;
  flex: 0 0 47%;
  padding: 8px 0 !important;
  height: 42px;
}

.ats-form-footer-button--back {
  background: #fbfbfb !important;
  border-width: 2px !important;
}
</style>
