import Vue from "vue";
import i18next from "i18next";
import VueI18Next from "@panter/vue-i18next";

import { formatDate } from "./formatDate";
import { formatDatetime } from "./formatDatetime";

import en from "./locales/en.json";

const availableLocales = {
  en: "English",
  et: "Eesti",
  ru: "Русский",
  fi: "Suomi",
  sv: "Svenska",
  no: "Norsk",
  de: "Deutsch",
};

const countryCodeToLocale = {
  EN: "en",
  EE: "et",
  RU: "ru",
  FI: "fi",
  SE: "sv",
  NO: "no",
  DE: "de",
};

const lazyTranslations = {
  en: () => import("./locales/en.json"),
  et: () => import("./locales/et.json"),
  ru: () => import("./locales/ru.json"),
  fi: () => import("./locales/fi.json"),
  sv: () => import("./locales/sv.json"),
  no: () => import("./locales/no.json"),
  de: () => import("./locales/de.json"),
};

class WebpackyBackend {
  read(language, namespace, callback) {
    const loader = lazyTranslations[language];
    if (!loader) return callback(null, true); // language missing
    loader()
      // .then((res) => callback(null, res.default.translations))
      .then((res) => {
        callback(null, res.default);
      })
      .catch((err) => callback(err, false));
  }
}
WebpackyBackend.type = "backend";

const supportedLngs = Object.keys(availableLocales);
const fallbackLng = "en";
const navigatorLng = navigator.language.trim().split(/-|_/)[0];
const i18nSelectedLang = supportedLngs.includes(navigatorLng)
  ? navigatorLng
  : fallbackLng;
const i18nIsDefaultLang = i18nSelectedLang === fallbackLng;

Vue.use(VueI18Next);

i18next.use(WebpackyBackend).init({
  // debug: true,
  lng: i18nSelectedLang,
  // lng: 'en', //Note that if this option is not set, I18next will try to perform language detection automatically and set the locale accordingly
  supportedLngs,
  nonExplicitSupportedLngs: true,
  // lowerCaseLng: true,
  // cleanCode: true,
  load: "languageOnly",
  // load: 'currentOnly',
  partialBundledLanguages: true,
  fallbackLng,
  // defaultNS: 'common',
  resources: {
    en,
  },
  interpolation: {
    format(value, format /*, lng*/) {
      if (format === "date") return formatDate(...arguments);
      if (format === "datetime") return formatDatetime(...arguments);
      //if(value instanceof Date) return moment(value).format(format) // "key": "The current date is {{date, MM/DD/YYYY}}",
      return value;
    },
  },
  // allow keys to be phrases having `:`, `.`
  // nsSeparator: false,
  // keySeparator: '.',
});

// import Flatpickr from 'flatpickr'
// const flatpickrLocales = {
//   et: require('flatpickr/dist/l10n/et').et,
//   // ru: require('flatpickr/dist/l10n/ru').ru
// }
// Flatpickr.localize(flatpickrLocales[i18next.language] || Flatpickr.l10ns.en)

// moment.locale(i18next.language)
// i18next.on('languageChanged', (lng) => {
//   moment.locale(lng)
//   Flatpickr.localize(flatpickrLocales[lng] || Flatpickr.l10ns.en)
// })

const i18n = new VueI18Next(i18next);

export default i18n;

export {
  availableLocales,
  countryCodeToLocale,
  i18nSelectedLang,
  i18nIsDefaultLang,
};
