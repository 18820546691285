<template>
  <span v-if="errors" class="tw-text-error">{{ errors }}</span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    errorKey: {
      require: true,
    },
  },
  computed: {
    ...mapGetters("atsForm", ["getFieldErrors"]),
    errors() {
      return this.getFieldErrors(this.errorKey);
    },
  },
};
</script>
