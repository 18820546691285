import axios from "axios";
import Cookie from "js-cookie";

const API_ENDPOINT = `${process.env.VUE_APP_API_ENDPOINT}`;

const connection = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: false,
});

connection.interceptors.request.use((config) => {
  const token = Cookie.get(process.env.VUE_APP_COOKIE_KEY, {
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
  });

  if (token) {
    config.headers.common["Authorization"] = "Bearer " + token;
  }

  return config;
});

connection.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let notify = process.env.NODE_ENV === "development";
    switch (error.response.status) {
      case 403: //unauthorized (no permissions)
        break;
      case 413: //Payload Too Large
      case 422: //Unprocessable Entity. normal form validation error
        notify = false;
        break;
      default:
        break;
    }

    if (notify) {
      // eslint-disable-next-line
      console.log(error);
    }

    return Promise.reject(error.response.data);
  }
);

export { connection, API_ENDPOINT };
