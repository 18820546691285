<template>
  <div class="d-flex flex-column flex-wrap" ref="signatureWrapper">
    <canvas ref="canvas" class="signature" :width="padWidth"></canvas>
    <div class="buttons">
      <button
        class="tw-text-15-bold tw-color-main tw-log-border-d4d9e2 buttons__button"
        @click.prevent="clear"
      >
        {{ $t("log.btn.clear") }}
      </button>
    </div>
    <input :name="name" v-show="false" :value="value" />
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import SignaturePad from "signature_pad";

export default {
  props: {
    name: {
      type: String,
    },
    signature: {
      type: String,
    },
  },
  data: function () {
    return {
      commit: "updateSignatures",
      signaturepad: undefined,
      padWidth: 200,
      value: null,
    };
  },
  methods: {
    clear() {
      this.signaturepad.clear();
      this.value = null;
    },
    save() {
      this.value = this.signaturepad.toDataURL();
    },
    resizeSignaturePad() {
      this.padWidth = this.$refs.signatureWrapper.offsetWidth;
    },
  },

  created() {
    window.addEventListener("resize", this.resizeSignaturePad);
  },

  mounted() {
    const canvas = this.$refs.canvas;

    this.signaturepad = new SignaturePad(canvas);

    this.signaturepad.addEventListener(
      "afterUpdateStroke",
      debounce(() => {
        this.save();
      }, 50)
    );

    Promise.resolve((this.padWidth = this.$refs.signatureWrapper.offsetWidth))
      .then(() => {
        this.value = this.signature;
        return this.signaturepad.fromDataURL(this.signature);
      })
      .catch(console.error);
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeSignaturePad);
  },

  watch: {
    signature: function (val) {
      if (val !== null) {
        this.signaturepad.fromDataURL(val);
        this.value = val;
      }
    },
    padWidth: function () {
      // if padWidth changes then sometimes signature disappears
      if (this.value !== null) {
        this.signaturepad.fromDataURL(this.signature);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signature {
  background-color: #e4e8ef !important;
  border-radius: 4px !important;
  border: 4px solid #e4e8ef;
  flex: 2;
}

.buttons {
  border-radius: 4px !important;
  display: flex;
  gap: 18px !important;
  justify-content: flex-end;
  margin-top: 8px !important;
  align-items: center;
}

.buttons__button {
  background-color: #fbfbfb !important;
  border-radius: 4px !important;
  padding: 7px 31px !important;
}
</style>
