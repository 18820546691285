<template>
  <div>
    <BFormSelect
      v-model="model"
      :disabled="isConfirmed"
      class="tw-bg-e4e8ef tw-text-15 tw-color-main tw-color-main-placeholder"
    >
      <BFormSelectOption :value="null" class="tw-text-15 tw-color-main">
        {{ "ATSi tüüp" }}
      </BFormSelectOption>
      <BFormSelectOption :value="1" class="tw-text-15 tw-color-main">
        {{ "Adresseeritud" }}
      </BFormSelectOption>
      <BFormSelectOption :value="2" class="tw-text-15 tw-color-main">
        {{ "Konventsionaalne" }}
      </BFormSelectOption>
    </BFormSelect>
    <AtsFormErrors :errorKey="stateKey" />
  </div>
</template>

<script>
import { BFormSelect, BFormSelectOption } from "bootstrap-vue";
import { mapGetters } from "vuex";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    AtsFormErrors,
    BFormSelect,
    BFormSelectOption,
  },
  data() {
    return {
      commit: "updateSection1",
      stateKey: "ats_type",
    };
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
    model: {
      get() {
        return this.storeKeyValue(this.commit, this.stateKey) || null;
      },
      set(value) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value,
        });
      },
    },
  },
};
</script>

<style lang="scss"></style>
