<template>
  <div>
    <BFormTimepicker
      class="tw-color-main tw-color-main-placeholder ats-form-input-time"
      :placeholder="placeholder"
      :hour12="false"
      v-bind="labels.et"
      now-button
      reset-button
      v-model="model"
      :disabled="isConfirmed"
    />
    <AtsFormErrors :errorKey="errorKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BFormTimepicker } from "bootstrap-vue";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    BFormTimepicker,
    AtsFormErrors,
  },
  props: {
    stateKey: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      labels: {
        et: {
          labelCloseButton: "Sulge",
          labelNoTimeSelected: "Kellaaega ei ole valitud",
          labelNow: "test",
          labelNowButton: "Praegu",
          labelResetButton: "Lähtestada",
        },
      },
    };
  },
  computed: {
    ...mapGetters("atsForm", ["time", "errors", "isConfirmed"]),
    model: {
      get() {
        return this.time(this.stateKey);
      },
      set(value) {
        this.$store.commit("atsForm/updateTime", { key: this.stateKey, value });
      },
    },
    errorKey() {
      return this.stateKey;
    },
  },
};
</script>

<style lang="scss">
.ats-form-input-time {
  background: #e4e8ef !important;
  border-radius: 4px !important;
  border: 4px solid #e4e8ef;
  border: none !important;
  outline-width: 0px !important;
  padding: 5px 10px !important;
}

.ats-form-input-time > label {
  color: #000054 !important;
}
</style>
