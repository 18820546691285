<template>
  <h2
    class="m-0 tw-text-18-bold"
    :class="{ 'ats-form-header2--mobile': mobile }"
  >
    {{ text }}
  </h2>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 764px) {
  .ats-form-header2--mobile {
    display: none !important;
  }
}
</style>
