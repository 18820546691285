<template>
  <button
    class="btn tw-button tw-text-15-bold log-edit-button"
    :class="buttonClass"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    btnClass: String,
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonClass() {
      if (this.btnClass === "back") {
        return "btn-primary tw-bg-fbfbfb tw-color-main log-edit-button--back";
      }

      if (this.btnClass === "primary") {
        return `btn-${this.btnClass} log-edit-button--confirm`;
      }

      return `btn-${this.btnClass}`;
    },
  },
};
</script>

<style lang="scss">
.log-edit-button {
  flex: 0 0 48%;
}

.log-edit-button--back {
  align-self: flex-start;
  margin-left: auto;
}

.log-edit-button--confirm {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .log-edit-button--back {
    margin-left: 0;
  }

  .log-edit-button--confirm {
    flex: 0 0 48%;
  }
}
</style>
