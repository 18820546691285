<template>
  <router-view></router-view>
</template>

<script>
import axios from "axios";

export default {
  name: "app",
  beforeCreate() {
    this.$store.dispatch("i18n/checkStorage");

    this.$store.dispatch("authenticate");

    axios(`${process.env.VUE_APP_API_ENDPOINT}/pub/countrycode`, {
      responseType: "json",
    })
      .then((res) => {
        this.$store.dispatch("i18n/setLocaleGeo", res.data.country_code);
      })
      .catch(console.error);
  },
};
</script>

<style lang="scss">
@import "./scss/styles";
</style>
