<template>
  <div class="ats-form-column">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.ats-form-column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
