<template>
  <div class="ats-form-devices-row">
    <div class="ats-form-devices__device">
      <div class="ats-form-devices-row-left ats-form-devices-row-left--pt">
        <AtsFormInputText
          :stateKey="name"
          commit="updateSection3Name"
          placeholder="Seadme nimi"
          :useErrorKey="`devices.${this.name}.name`"
        />
        <AtsFormInputText
          :stateKey="name"
          :useErrorKey="`devices.${this.name}.brand`"
          commit="updateSection3Brand"
          placeholder="Seadme mark"
        />
        <AtsFormInputText
          :stateKey="name"
          :useErrorKey="`devices.${this.name}.quantity`"
          commit="updateSection3Quantity"
          placeholder="Kogus (tk)"
          type="number"
        />
      </div>
      <div class="ats-form-devices-row-right">
        <AtsFormYesNo
          text="Testitud"
          :stateKey="name"
          commit="updateSection3Testing"
          single
          left
          :useErrorKey="`devices.${this.name}.is_tested`"
        />
        <AtsFormYesNo
          text="Hooldatud"
          :stateKey="name"
          commit="updateSection3Maintained"
          single
          right
          :useErrorKey="`devices.${this.name}.is_tested`"
        />
      </div>
    </div>
    <div class="ats-form-devices__device">
      <AtsFormTextarea
        :placeholder="$t('Tsooni number ja/või märkus')"
        :stateKey="name"
        commit="updateSection3Remark"
        rows="1"
        class="ats-form-devices-row-left"
      />
      <AtsFormButtonDelete :name="name" mobile @click="deleteDevice(name)" />
      <AtsFormButtonDelete :name="name" @click="deleteDevice(name)" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import AtsFormInputText from "@/components/AtsFormInputText.vue";
import AtsFormYesNo from "@/components/AtsFormYesNo.vue";
import AtsFormTextarea from "@/components/AtsFormTextarea.vue";
import AtsFormButtonDelete from "@/components/AtsFormButtonDelete.vue";

export default {
  components: {
    AtsFormInputText,
    AtsFormYesNo,
    AtsFormTextarea,
    AtsFormButtonDelete,
  },
  props: {
    name: Number,
  },
  methods: {
    ...mapMutations("atsForm", ["deleteDevice"]),
  },
};
</script>

<style lang="scss" scoped>
.ats-form-devices-row {
  display: flex;
  gap: 11px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ats-form-devices__device {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  gap: 22px;
}

.ats-form-devices-row-left {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 22px;
}

.ats-form-devices-row-left--pt {
  padding-top: 15px;
}

.ats-form-devices-row-right {
  display: flex;
}

@media (min-width: 768px) {
  .ats-form-devices-row {
    flex-flow: row;
  }

  .ats-form-devices__device {
    flex-direction: row;
    flex: 0 1 47%;
  }
}
</style>
