<template>
  <div
    class="ats-form-row"
    :class="{
      'ats-form-row--column': column,
      'ats-form-row--desktop': desktop,
      'ats-form-row--single': single,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Boolean,
      required: false,
    },
    desktop: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.ats-form-row {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
}

.ats-form-row > * {
  flex: 1 1 1px;
}

.ats-form-row--column {
  align-items: stretch;
  flex-direction: column;
}

.ats-form-row--desktop {
  display: none !important;
}

.ats-form-row--single > * {
  flex: 1;
}

@media (min-width: 768px) {
  .ats-form-row > * {
    flex: 1 1 46% !important;
    max-width: 47%;
  }

  .ats-form-row--column {
    flex-direction: row;
  }

  .ats-form-row--desktop {
    display: flex !important;
  }
}
</style>
