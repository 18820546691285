<template>
  <div class="page">
    <div v-if="loading" class="app-wait"></div>
    <div v-else-if="error || !productData || !theme" class="p-3">
      {{ error || $t("product.error_loading") }}
    </div>
    <div v-else>
      <component
        :is="themeComponent"
        v-if="themeComponent"
        :product-data="productData"
        @openTroubleshooting="openTroubleshooting"
      />
    </div>
    <troubleshooting
      v-if="troubleshootingId"
      :id.sync="troubleshootingId"
      :uuid="uuid"
      :tenantId="tenantId"
      :theme="theme"
      :product="productData"
    />
  </div>
</template>

<script>
import productApi from "@/api/modules/product.js";
import { mapActions, mapMutations } from "vuex";

const Troubleshooting = () => import("@/views/Troubleshooting");
const ProductThemeDefault = () =>
  import("@/views/products/themes/ProductThemeDefault.vue");
const ProductThemeBeleduc = () =>
  import("@/views/products/themes/ProductThemeBeleduc.vue");
const ProductThemeBeleducPortal = () =>
  import("@/views/products/themes/ProductThemeBeleducPortal.vue");

export default {
  name: "product-overview",

  components: {
    Troubleshooting,
    ProductThemeDefault,
    ProductThemeBeleduc,
    ProductThemeBeleducPortal,
  },

  props: {
    tenantId: {
      type: [String, Number],
      required: true,
    },
    uuid: {
      type: [String],
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      error: null,

      productData: null,
      theme: null,
      troubleshootingId: null,
      log: false,
    };
  },

  computed: {
    themeComponent() {
      return this.theme ? `product-theme-${this.theme}` : null;
    },
  },

  async created() {
    await this.loadProduct();
    this.loading = false;
    this.log = true;
  },

  methods: {
    ...mapMutations("process", ["updateTenantName"]),
    ...mapActions("process", ["openProcess", "openProduct", "openProductWithQr"]),

    async loadProduct() {
      try {
        const response = await productApi.getProduct({
          tenantId: this.tenantId,
          uuid: this.uuid,
        });
        this.productData = response.data.data;
        this.theme = this.productData.theme;
        this.updateTenantName(response.data.data.tenant_name);


        let params = (new URL(document.location)).searchParams;
        let searchParams = new URLSearchParams(params);

        if (searchParams.has("qr") === true) {
          this.openProductWithQr({
            product_code: this.productData.product.code,
            product_name: this.productData.product.name_json.en
          });
        } else {
          this.openProduct({
            product_code: this.productData.product.code,
            product_name: this.productData.product.name_json.en
          });
        }

      } catch (e) {
        return;
      }
    },

    openTroubleshooting(id) {
      this.troubleshootingId = id;

      const processName = this.productData.troubleshootings.find(
        (process) => process.id === id
      ).name_json["en"];
      this.openProcess({
        id,
        name: processName,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/styles";
</style>
