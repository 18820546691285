<template>
  <div class="tw-log-border-d4d9e2 log-list-pagination">
    <button
      class="tw-log-border-d4d9e2 log-list-pagination__button log-list-pagination__button--arrow-left"
      @click="$emit('first')"
    ></button>
    <button
      class="tw-log-border-d4d9e2 log-list-pagination__button log-list-pagination__button--left"
      @click="$emit('prev')"
    ></button>
    <div class="log-list-pagination-numbers">
      <span class="tw-text-15 log-list-pagination-numbers__number">
        {{ entries.current_page }}
      </span>
    </div>
    <button
      class="tw-log-border-d4d9e2 log-list-pagination__button log-list-pagination__button--right"
      @click="$emit('next')"
    ></button>
    <button
      class="tw-log-border-d4d9e2 log-list-pagination__button log-list-pagination__button--arrow-right"
      @click="$emit('last')"
    ></button>
  </div>
</template>

<script>
export default {
  props: {
    entries: Object,
  },
};
</script>

<style lang="scss" scoped>
.log-list-pagination {
  align-items: center;
  background-color: #fbfbfb !important;
  display: flex;
  height: 58px !important;
  padding: 14px 37px !important;
}

.log-list-pagination__button {
  background-color: #f6f8fa;
  height: 29px !important;
  width: 29px !important;
}

.log-list-pagination__button--left {
  margin-left: 9px !important;
  background: no-repeat center
    url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6567 0.14619L0.150823 5.79444C0.0541208 5.89365 0 6.02671 0 6.16525C0 6.30379 0.0541208 6.43685 0.150823 6.53606L5.6567 12.1864C5.70174 12.2327 5.7556 12.2695 5.81509 12.2946C5.87458 12.3197 5.9385 12.3326 6.00307 12.3326C6.06764 12.3326 6.13156 12.3197 6.19105 12.2946C6.25054 12.2695 6.3044 12.2327 6.34945 12.1864C6.44212 12.0916 6.494 11.9642 6.494 11.8316C6.494 11.6989 6.44212 11.5716 6.34945 11.4767L1.17189 6.16525L6.34945 0.854877C6.4418 0.760047 6.49349 0.632905 6.49349 0.500533C6.49349 0.368161 6.4418 0.24102 6.34945 0.14619C6.3044 0.0999246 6.25054 0.0631527 6.19105 0.0380443C6.13156 0.0129359 6.06764 0 6.00307 0C5.9385 0 5.87458 0.0129359 5.81509 0.0380443C5.7556 0.0631527 5.70174 0.0999246 5.6567 0.14619Z' fill='%23446883'/%3E%3C/svg%3E%0A");
}

.log-list-pagination__button--right {
  margin-right: 9px !important;
  background: no-repeat center
    url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.3433 12.8538L6.84918 7.20556C6.94588 7.10635 7 6.97329 7 6.83475C7 6.69621 6.94588 6.56315 6.84918 6.46394L1.3433 0.81356C1.29826 0.767295 1.2444 0.730523 1.18491 0.705415C1.12542 0.680306 1.0615 0.667371 0.996929 0.667371C0.932356 0.667371 0.868438 0.680306 0.808947 0.705415C0.749457 0.730523 0.695599 0.767295 0.650553 0.81356C0.55788 0.908439 0.505997 1.03581 0.505997 1.16844C0.505997 1.30106 0.55788 1.42843 0.650553 1.52331L5.82812 6.83475L0.650552 12.1451C0.558197 12.24 0.506515 12.3671 0.506515 12.4995C0.506515 12.6318 0.558197 12.759 0.650552 12.8538C0.695598 12.9001 0.749455 12.9368 0.808946 12.962C0.868437 12.9871 0.932355 13 0.996928 13C1.0615 13 1.12542 12.9871 1.18491 12.962C1.2444 12.9368 1.29826 12.9001 1.3433 12.8538V12.8538Z' fill='%23446883'/%3E%3C/svg%3E%0A");
}

.log-list-pagination__button--arrow-left {
  background: no-repeat center
    url("data:image/svg+xml,%3Csvg width='11' height='13' viewBox='0 0 11 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6567 0.14619C5.70174 0.0999246 5.7556 0.0631527 5.81509 0.0380443C5.87458 0.0129359 5.9385 0 6.00307 0C6.06765 0 6.13156 0.0129359 6.19105 0.0380443C6.25055 0.0631527 6.3044 0.0999246 6.34945 0.14619C6.4418 0.24102 6.49349 0.368161 6.49349 0.500533C6.49349 0.632905 6.4418 0.760047 6.34945 0.854877L1.17189 6.16525L6.34945 11.4767C6.39606 11.5244 6.43281 11.5808 6.4576 11.6428C6.4824 11.7047 6.49476 11.7709 6.49397 11.8376C6.49318 11.9043 6.47926 11.9702 6.453 12.0315C6.42675 12.0928 6.38867 12.1483 6.34095 12.1949C6.29549 12.2394 6.24172 12.2744 6.18271 12.298C6.1237 12.3216 6.06062 12.3334 5.99706 12.3326C5.93351 12.3318 5.87073 12.3185 5.81233 12.2934C5.75392 12.2683 5.70104 12.232 5.6567 12.1864L0.150823 6.53606C0.0541208 6.43685 0 6.30379 0 6.16525C0 6.02671 0.0541208 5.89365 0.150823 5.79444L5.6567 0.14619ZM9.9067 0.14619C9.95174 0.0999246 10.0056 0.0631527 10.0651 0.0380443C10.1246 0.0129359 10.1885 0 10.2531 0C10.3176 0 10.3816 0.0129359 10.4411 0.0380443C10.5005 0.0631527 10.5544 0.0999246 10.5994 0.14619C10.6918 0.24102 10.7435 0.368161 10.7435 0.500533C10.7435 0.632905 10.6918 0.760047 10.5994 0.854877L5.42189 6.16525L10.5994 11.4767C10.6461 11.5244 10.6828 11.5808 10.7076 11.6428C10.7324 11.7047 10.7448 11.7709 10.744 11.8376C10.7432 11.9043 10.7293 11.9702 10.703 12.0315C10.6767 12.0928 10.6387 12.1483 10.5909 12.1949C10.5455 12.2394 10.4917 12.2744 10.4327 12.298C10.3737 12.3216 10.3106 12.3334 10.2471 12.3326C10.1835 12.3318 10.1207 12.3185 10.0623 12.2934C10.0039 12.2683 9.95104 12.232 9.9067 12.1864L4.40082 6.53606C4.30412 6.43685 4.25 6.30379 4.25 6.16525C4.25 6.02671 4.30412 5.89365 4.40082 5.79444L9.9067 0.14619Z' fill='%23446883'/%3E%3C/svg%3E%0A");
}

.log-list-pagination__button--arrow-right {
  background: no-repeat center
    url("data:image/svg+xml,%3Csvg width='11' height='13' viewBox='0 0 11 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.3433 12.8538C5.29826 12.9001 5.2444 12.9368 5.18491 12.962C5.12542 12.9871 5.0615 13 4.99693 13C4.93235 13 4.86844 12.9871 4.80895 12.962C4.74945 12.9368 4.6956 12.9001 4.65055 12.8538C4.5582 12.759 4.50651 12.6318 4.50651 12.4995C4.50651 12.3671 4.5582 12.24 4.65055 12.1451L9.82811 6.83475L4.65055 1.52331C4.60395 1.47559 4.56719 1.41916 4.5424 1.35724C4.5176 1.29532 4.50524 1.22912 4.50603 1.16242C4.50682 1.09573 4.52074 1.02984 4.547 0.968522C4.57325 0.907205 4.61133 0.85166 4.65905 0.805061C4.70451 0.760638 4.75828 0.725615 4.81729 0.701996C4.8763 0.678378 4.93939 0.666626 5.00294 0.667416C5.06649 0.668205 5.12927 0.68152 5.18767 0.706597C5.24608 0.731675 5.29896 0.768021 5.3433 0.81356L10.8492 6.46394C10.9459 6.56315 11 6.69621 11 6.83475C11 6.97329 10.9459 7.10635 10.8492 7.20556L5.3433 12.8538ZM1.0933 12.8538C1.04826 12.9001 0.994398 12.9368 0.934908 12.962C0.875417 12.9871 0.8115 13 0.746927 13C0.682355 13 0.618436 12.9871 0.558946 12.962C0.499455 12.9368 0.445598 12.9001 0.400552 12.8538C0.308196 12.759 0.256515 12.6318 0.256515 12.4995C0.256515 12.3671 0.308196 12.24 0.400552 12.1451L5.57812 6.83475L0.400553 1.52331C0.353945 1.47559 0.317194 1.41916 0.292397 1.35724C0.267599 1.29532 0.255242 1.22912 0.256032 1.16242C0.256822 1.09573 0.270742 1.02984 0.296997 0.968521C0.323253 0.907205 0.361328 0.85166 0.409052 0.80506C0.45451 0.760637 0.508283 0.725615 0.567291 0.701996C0.626299 0.678377 0.689386 0.666626 0.75294 0.667416C0.816495 0.668204 0.879271 0.68152 0.937674 0.706596C0.996078 0.731674 1.04896 0.768021 1.0933 0.81356L6.59918 6.46394C6.69588 6.56315 6.75 6.69621 6.75 6.83475C6.75 6.97329 6.69588 7.10635 6.59918 7.20556L1.0933 12.8538Z' fill='%23446883'/%3E%3C/svg%3E%0A");
}

.log-list-pagination-numbers {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 22px !important;
}
</style>
