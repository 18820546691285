import { connection } from '../connection.js';

export default {
  getProduct({ tenantId, uuid }) {
    return connection.get(`pub/product/${tenantId}/${uuid}`);
  },
  sendQrCode({ tenantId, uuid, email }) {
    return connection.post(`pub/product/${tenantId}/${uuid}/sendqrcode`, {
      email,
    });
  },
};
