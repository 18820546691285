<template>
  <div class="ats-form-header">
    <div class="ats-form-header__inner">
      <AtsFormInput
        stateKey="customer_name"
        commit="updateHeader"
        placeholder="Tellija"
      />
      <AtsFormInput
        stateKey="contact_person"
        commit="updateHeader"
        placeholder="Kontaktisik"
      />
      <AtsFormInput
        stateKey="contact_person_number"
        commit="updateHeader"
        placeholder="Kontakttelefon"
      />
    </div>
    <div class="ats-form-header__inner">
      <AtsFormInput
        stateKey="maintenance_contract_number"
        commit="updateHeader"
        placeholder="Hoolduslepingu nr"
      />
      <AtsFormInput
        stateKey="building_address"
        commit="updateHeader"
        placeholder="Ehitise aadress"
      />
      <AtsFormInput
        stateKey="building_name"
        commit="updateHeader"
        placeholder="Ehitise nimetus"
      />
    </div>
  </div>
</template>

<script>
import AtsFormInput from "@/components/AtsFormInput.vue";

export default {
  components: {
    AtsFormInput,
  },
};
</script>

<style lang="scss" scoped>
.ats-form-header {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  justify-content: space-between;
}

.ats-form-header__inner {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  gap: 14px;
}

@media (min-width: 768px) {
  .ats-form-header__inner {
    flex-basis: 47%;
  }
}
</style>
