<template>
  <div v-if="single">
    <div
      class="tw-text-15-bold ats-form-yesno-single"
      :class="{
        'ats-form-yesno__checkbox--checked': isYes,
        'ats-form-yesno-single--left': left,
        'ats-form-yesno-single--right': right,
      }"
      @click="checkSingle"
    >
      {{ text }}
    </div>
    <AtsFormErrors v-if="single" :errorKey="errorKey" />
  </div>
  <div v-else>
    <div
      class="d-flex justify-content-between align-items-center align-self-start"
    >
      <div class="tw-text-15 ats-form-yesno__text">{{ text }}</div>
      <div
        class="tw-text-15-bold ats-form-yesno__checkbox ats-form-yesno__checkbox--left"
        :class="{ 'ats-form-yesno__checkbox--checked': isYes }"
        @click="checkDouble(true)"
      >
        {{ "Jah" }}
      </div>
      <div
        class="tw-text-15-bold ats-form-yesno__checkbox ats-form-yesno__checkbox--right"
        :class="{ 'ats-form-yesno__checkbox--checked': isNo }"
        @click="checkDouble(false)"
      >
        {{ "Ei" }}
      </div>
    </div>
    <AtsFormErrors v-if="!single" :errorKey="errorKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: { AtsFormErrors },
  props: {
    stateKey: {
      required: true,
    },
    commit: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      required: false,
    },
    right: {
      type: Boolean,
      required: false,
    },
    useErrorKey: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
    value() {
      return this.storeKeyValue(this.commit, this.stateKey);
    },
    isYes() {
      return this.value;
    },
    isNo() {
      return this.value === undefined || this.value === null
        ? false
        : !this.value;
    },
    errorKey() {
      return this.useErrorKey || this.stateKey;
    },
  },
  methods: {
    checkSingle() {
      if (!this.isConfirmed) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value: !this.value,
        });
      }
    },
    checkDouble(value) {
      if (!this.isConfirmed) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.ats-form-yesno-single {
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 100px !important;
  padding: 14px 0 !important;
  align-self: flex-start;
}

.ats-form-yesno__text {
  align-items: center;
  color: #000054 !important;
  display: flex;
  flex: 1;
  padding-right: 8px;
}

.ats-form-yesno__checkbox {
  align-items: center;
  border: 1px solid #e4e8ef;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-height: 49px !important;
  padding: 14px;
  text-align: center;
  width: 59px !important;
  align-self: flex-start;
}

.ats-form-yesno__checkbox--left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.ats-form-yesno__checkbox--right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.ats-form-yesno__checkbox--checked {
  background-color: #000054 !important;
  border-color: #000054 !important;
  color: #fff !important;
}

.ats-form-yesno-single--left {
  border-radius: 4px 0 0 4px !important;
  border: 1px solid #d4d9e2;
  align-self: flex-start;
}

.ats-form-yesno-single--right {
  border-radius: 0 4px 4px 0 !important;
  border: 1px solid #d4d9e2;
  align-self: flex-start;
}
</style>
