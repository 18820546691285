<template>
  <div
    class="ats-form-block tw-font-bold"
    :class="{
      'ats-form-block--padding': padding,
      'ats-form-block--gap': gap,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    gap: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ats-form-block {
  display: flex;
  flex-direction: column;
}

.ats-form-block--padding {
  padding: 0 15px !important;
}

.ats-form-block--gap {
  gap: 24px !important;
}

@media screen and (min-width: 764px) {
  .ats-form-block--padding {
    padding: 0 32px !important;
  }
}
</style>
