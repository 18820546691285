const selectGroup = (group, groupName) =>
  group.find((row) => row.group.name_json.en === groupName);

const selectMeta = (group, metaName) =>
  group.meta.find((row) => row.name_json.en === metaName);

export const utilityProductMeta = {
  selectGroup,
  selectMeta,
};
