<template>
  <div v-if="isReady" class="form-response-container">
    <div class="form-response-container__container">
      <h1 class="form-response-header">{{ form.data.name | jLang }}</h1>
      <p class="form-response-description">
        {{ form.data.description | jLang }}
      </p>
    </div>
    <BForm ref="bForm" :class="{ 'form-response-grid': hasSections }">
      <template v-for="field in form.data.fields">
        <template v-if="field.type === 'meta_field'"></template>

        <h3
          v-else-if="field.type === 'header'"
          :key="field.id + '-header'"
          class="form-response-header form-response-side-p form-response-grid-span-2"
        >
          {{ field.question | jLang }}
        </h3>

        <p
          v-else-if="field.type === 'header_field'"
          :key="field.id + '-header_field'"
          class="tw-text-15 form-response-m-0 form-response-mb-16px form-response-side-p"
        >
          {{ getHeaderFieldLabel(field) }}: {{ getHeaderFieldValue(field) }}
        </p>

        <div
          v-else-if="field.type === 'section'"
          :key="field.id + '-section'"
          class="form-response-divider form-response-grid-span-2"
        ></div>

        <BFormGroup
          v-else-if="field.type === 'boolean'"
          :key="field.id + '-group-boolean'"
          class="tw-text-15 form-response-side-p form-response-boolean"
          :label="field.question | jLang"
        >
          <FormResponseRadioButtons
            :name="field.id.toString()"
            :options="field.options"
            v-model="formMutliYesNoValues[field.id]"
          />
          <!-- <template v-for="children in field.fields">
            <div
              v-if="showMutliLevelYesNoChild(field.id, children)"
              :key="children.id"
              class="mt-2"
            >
              {{ children.question | jLang }}
              <FormResponseRadioButtons
                :name="children.id.toString()"
                v-model="formMutliYesNoValues[children.id]"
              />
              <template v-for="children2 in children.fields">
                <div
                  v-if="showMutliLevelYesNoChild(children.id, children2)"
                  :key="children2.id"
                  class="mt-2"
                >
                  {{ children2.question | jLang }}
                  <FormResponseRadioButtons
                    :name="children2.id.toString()"
                    v-model="formMutliYesNoValues[children2.id]"
                  />
                </div>
              </template>
            </div>
          </template> -->
        </BFormGroup>

        <template v-else-if="field.type === 'boolean_multi'"></template>

        <BFormGroup
          v-else
          :key="field.id + '-group'"
          class="tw-text-15 form-response-side-p"
          :label="field.question | jLang"
        >
          <BFormInput
            v-if="field.type === 'text'"
            type="text"
            class="form-response-input"
            :value="getFieldAnswer(field)"
            :name="field.id.toString()"
          />

          <BFormInput
            v-else-if="field.type === 'password'"
            type="password"
            class="form-response-input"
            :value="getFieldAnswer(field)"
            :name="field.id.toString()"
          />

          <BFormInput
            v-else-if="field.type === 'number'"
            type="number"
            class="form-response-input"
            :value="getFieldAnswer(field)"
            :name="field.id.toString()"
          />

          <BFormSelect
            v-else-if="field.type === 'select'"
            class="form-response-select"
            :name="field.id.toString()"
            :value="getFieldAnswer(field)"
          >
            <BFormSelectOption
              v-for="(value, key) of field.options"
              :key="key"
              :value="value"
            >
              {{ value }}
            </BFormSelectOption>
          </BFormSelect>

          <div v-else-if="field.type === 'radio'" class="d-flex flex-column">
            <BFormRadio
              v-for="(option, optionKey) of field.options"
              :key="optionKey"
              :name="field.id.toString()"
              :value="option"
              v-model="formMutliYesNoValues[field.id]"
            >
              {{ option }}
            </BFormRadio>
          </div>

          <div v-else-if="field.type === 'checkbox'" class="d-flex flex-column">
            <BFormCheckboxGroup
              v-model="formMutliYesNoValues[field.id]"
              stacked
            >
              <BFormCheckbox
                v-for="(option, optionKey) of field.options"
                :key="optionKey"
                :name="field.id.toString()"
                :value="option"
              >
                {{ option }}
              </BFormCheckbox>
            </BFormCheckboxGroup>
          </div>

          <template v-else-if="field.type === 'upload'">
            <BFormFile
              class="field-drag-and-drop--mobile"
              :name="field.id.toString()"
              data-upload="true"
              accept="image/*"
              @input="formFileUploadMobile($event, field.id)"
              style="display: none"
              :capture="true"
              :plain="true"
              :id="field.id.toString()"
            />
            <label
              :for="field.id.toString()"
              class="tw-text-15-bold tw-color-main tw-log-border-d4d9e2 buttons__button field-drag-and-drop--mobile"
              style="
                background-color: #fbfbfb !important;
                border-radius: 4px !important;
                padding: 15px 34px !important;
              "
            >
              {{ $t("common.takePicture") }}
            </label>

            <div
              class="field-drag-and-drop field-drag-and-drop--desktop"
              @click="formFileUploadClick($event)"
              @drop.prevent="formFileUpload($event, field.id)"
              @dragover.prevent
              @dragenter.prevent="formFileUploadDragEnter"
              @dragleave.prevent="formFileUploadDragLeave"
            >
              <div class="field-drag-and-drop__block">
                <input
                  capture="true"
                  class="field-drag-and-drop__input"
                  :name="field.id.toString()"
                  type="file"
                  accept="image/*"
                  @change.prevent="formFileUpload($event, field.id)"
                />
                <img
                  src="@/assets/svg/FileUploadCloud.svg"
                  alt="Cloud"
                  class="field-drag-and-drop__image"
                />
                <p class="field-drag-and-drop__text">
                  {{ $t("common.dragAndDropImage") }}
                </p>
              </div>
            </div>
            <div
              class="field-drag-and-drop__preview"
              v-if="
                getFieldAnswer(field, false) !== null &&
                getFieldAnswer(field, false) !== undefined
              "
            >
              <a
                v-if="getFieldAnswer(field, false).url !== ''"
                :href="getFieldAnswer(field, false).url"
              >
                <img
                  :src="getFieldAnswer(field, false).url"
                  :alt="getFieldAnswer(field, false).alt"
                  style="width: 100px; height: 100px; object-fit: cover"
                />
                <a
                  @click.prevent="deleteFile(getFieldAnswer(field, false).id)"
                  style="color: #c34a4a; margin-left: 10px"
                >
                  {{ "X" }}
                </a>
              </a>
            </div>
          </template>

          <BFormTextarea
            v-else-if="field.type === 'textarea'"
            :name="field.id.toString()"
            rows="4"
            :value="getFieldAnswer(field)"
            class="tw-text-15 tw-color-main tw-color-main-placeholder form-response-textarea"
          />

          <FormResponseSignature
            v-else-if="field.type === 'signature'"
            :name="field.id.toString()"
            :signature="getFieldAnswer(field)"
            :class="{ 'form-response-signature-in-grid': hasSections }"
          />

          <template v-else-if="field.type === 'image' && field.file">
            <BImg :src="field.file.url" :alt="field.file.alt" fluid />
          </template>
        </BFormGroup>
      </template>

      <div
        class="form-response-footer form-response-side-p form-response-grid-span-2"
      >
        <div class="form-response-footer__container">
          <FormResponseFooterButton back @click="goBack">
            {{ $t("common.back") }}
          </FormResponseFooterButton>
        </div>
        <div class="form-response-footer__container justify-content-between">
          <FormResponseFooterButton
            primary
            :disabled="isFinished"
            @click="sendForm(false)"
          >
            {{ $t("troubleshooting.save") }}
          </FormResponseFooterButton>
          <FormResponseFooterButton
            @click="sendForm"
            :disabled="is_sent || isFinished"
          >
            {{ $t("troubleshooting.btn_confirm") }}
          </FormResponseFooterButton>
        </div>
      </div>
    </BForm>
  </div>
  <AppLoader v-else />
</template>

<script>
import "@/scss/form-response.scss";

import formApi from "@/api/modules/form.js";
import AppLoader from "@/components/AppLoader.vue";
import FormResponseFooterButton from "@/components/FormResponseFooterButton.vue";
import FormResponseRadioButtons from "@/components/FormResponseRadioButtons.vue";
import FormResponseSignature from "@/components/FormResponseSignature.vue";
import { jLang } from "@/filters/filters.js";
import { formatDate } from "@/i18n/formatDate";
import {
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BImg,
} from "bootstrap-vue";
import { get, has, isString, merge, omitBy, set } from "lodash-es";

export default {
  name: "FormResponse",
  components: {
    AppLoader,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BImg,
    FormResponseFooterButton,
    FormResponseRadioButtons,
    FormResponseSignature,
  },
  data() {
    return {
      form: null,
      answers: null,
      answersMeta: null,
      is_sent: false,
      formMutliYesNoValues: {},
      formResponseId: undefined,
      status: "init",
      files: [],
    };
  },
  filters: {
    jLang,
  },
  computed: {
    isReady() {
      return this.status === "ready";
    },
    formId() {
      return this.$route.params.formId;
    },
    isFinished() {
      return get(this.answersMeta, "finished", false);
    },

    hasSections() {
      return this.form.data.fields.some((field) => field.type === "section");
    },
  },
  methods: {
    getHeaderFieldLabel(field) {
      return field.optional.value.label;
    },
    getHeaderFieldValue(field) {
      const value = get(field, "optional.value.value");

      const optionalType = get(field, "optional.value.type");
      if (optionalType === "date") {
        return formatDate(value);
      }

      return value;
    },

    showMutliLevelYesNoChild(fieldId, child) {
      return this.formMutliYesNoValues[fieldId] === child.optional.value;
    },

    goBack() {
      this.$router.back();
    },

    async deleteFile(formFileId) {
      await formApi.deleteFile(this.formResponseId, formFileId);
      location.reload();
    },

    getFieldAnswer(questionField, needParse = true) {
      let answer = null;
      if (this.answers) {
        this.answers.forEach((item) => {
          if (item.answer && answer === null) {
            if (Number(questionField.id) === Number(item.answer.field_id)) {
              if (questionField.type === "upload") {
                answer = item.answer.file;
              } else {
                answer = item.answer.answer;
              }
            }
          }
        });
      }

      if (needParse) {
        return JSON.parse(answer);
      } else {
        return answer;
      }
    },

    async fetchForm() {
      const formId = this.$route.params.formId;
      const uuid = this.$route.params.uuid;
      this.status = "init";
      this.form = await formApi.getForm(formId, uuid);
      await this.fetchAnswers();
      this.status = "ready";
    },

    async fetchAnswers() {
      if (this.$route.query.responseId) {
        const response = await formApi.getResponse(
          this.$route.query.responseId
        );

        this.answers = get(response, "data.data");
        this.answersMeta = get(response, "data.meta");

        if (this.answers) {
          const isMutliYesNoValue = (input) =>
            ["radio", "boolean", "boolean_multi", "checkbox"].includes(input);

          const parseMultiYesNoValues = (acc, curr) => {
            if (curr.answer) {
              let parsedValue = JSON.parse(curr.answer.answer);

              parsedValue =
                curr.question.type !== "radio" && isString(parsedValue)
                  ? parsedValue === "true"
                  : parsedValue;

              acc[curr.question.id] = parsedValue;
            }

            return acc;
          };

          this.formMutliYesNoValues = this.answers
            .filter((row) => isMutliYesNoValue(row.question.type))
            .reduce(parseMultiYesNoValues, {});
        }
      }
    },

    formFileUploadDragEnter(event) {
      event.currentTarget.classList.add("field-drag-and-drop--drag-enter");
    },
    formFileUploadDragLeave(event) {
      if (event.currentTarget.contains(event.relatedTarget)) {
        return;
      }
      event.currentTarget.classList.remove("field-drag-and-drop--drag-enter");
    },

    formFileUploadClick(event) {
      event.currentTarget.querySelector(".field-drag-and-drop__input").click();
    },

    formFileUpload(event, field_id) {
      const files = event.target.files || event.dataTransfer.files;

      let areAllFileImages = true;
      for (const file of files) {
        if (!file.type.startsWith("image/")) {
          areAllFileImages = false;
          break;
        }
      }

      if (areAllFileImages) {
        this.sendForm(files, field_id, false).then(() => location.reload());
      }
    },

    formFileUploadMobile(file, field_id) {
      this.sendForm([file], field_id, false).then(() => location.reload());
    },

    async sendForm(files, field_id = null, finished = true) {
      try {
        const formId = this.$route.params.formId;
        const entryId = this.$route.params.entryId;
        const responseId = this.formResponseId;
        const form = new FormData(this.$refs.bForm);

        let formProps = Object.fromEntries(form);
        formProps = omitBy(formProps, (_, key) => key.startsWith("__BVID__"));
        formProps = merge(formProps, this.formMutliYesNoValues);

        let headerFields = this.form.data.fields
          .filter((row) => row.type === "header_field")
          .reduce((acc, curr) => {
            acc[curr.id] = curr.optional.value.value;
            return acc;
          }, {});
        formProps = merge(formProps, headerFields);

        if (finished === true) {
          this.is_sent = true;
        }

        let dataToSend = {
          fields: [],
          finished: finished,
        };

        Object.entries(formProps).forEach((element) => {
          dataToSend.fields.push({
            id: element[0],
            answer: element[1],
          });
        });

        let filesToSend = [];
        if (files) {
          for (const file of files) {
            filesToSend.push({ field_id, file });
          }
        }

        const saveFormResponse = await formApi.saveForm(
          formId,
          entryId,
          dataToSend,
          responseId
        );

        if (saveFormResponse.data.success) {
          this.updateUrlQuery(saveFormResponse.data.form_response_id);
          this.formResponseId = saveFormResponse.data.form_response_id;
        } else {
          throw Error("saveFormResponse success is false");
        }

        for (let value of filesToSend) {
          await formApi.saveFile(
            saveFormResponse.data.form_response_id,
            value.field_id,
            value.file
          );
        }

        const successMessage = this.is_sent
          ? "Kanne kinnitatud."
          : "Kanne salvestatud.";
        this.$root.$bvToast.toast(successMessage, {
          // title: this.$t("common.success"),
          variant: "success",
        });

        if (has(this.answersMeta, "finished")) {
          this.answersMeta.finished = finished;
        } else {
          set(this, "answersMeta", { finished });
        }
      } catch (error) {
        console.error(error);
        const errorMessage = this.is_sent
          ? "Kinnitamisel esines vigu."
          : "Salvestamise esines vigu.";
        this.$root.$bvToast.toast(errorMessage, {
          // title: this.$t("common.error"),
          variant: "danger",
        });
      }
    },

    updateUrlQuery(responseId) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("responseId", responseId);
      history.replaceState(null, null, "?" + queryParams.toString());
    },
  },

  beforeMount() {
    this.fetchForm();
    this.formResponseId = this.$route.query.responseId;
    this.$store.commit("rootSetSelectedEntryId", this.$route.params.entryId);
  },
};
</script>
