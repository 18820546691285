<template>
  <div>
    <input
      :type="type"
      :placeholder="placeholder"
      v-model="model"
      class="tw-text-15 tw-text-15-placeholder tw-color-main ats-form-input"
      :disabled="isConfirmed"
    />
    <AtsFormErrors :errorKey="errorKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    AtsFormErrors,
  },
  props: {
    stateKey: {
      required: true,
    },
    commit: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: String,
    useErrorKey: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
    model: {
      get() {
        return this.storeKeyValue(this.commit, this.stateKey);
      },
      set(value) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value,
        });
      },
    },
    errorKey() {
      return this.useErrorKey || this.stateKey;
    },
  },
};
</script>

<style lang="scss">
.ats-form-input,
.ats-form-input:focus {
  background-color: #e4e8ef !important;
  border-radius: 4px !important;
  border: 1px solid #d4d9e2 !important;
  outline-width: 0px !important;
  padding: 10px !important;
  width: 100%;
}

input::-webkit-input-placeholder {
  color: #A8A8BE !important;
}

input:-moz-placeholder { /* Firefox 18- */
  color: #A8A8BE !important;
}

input::-moz-placeholder {  /* Firefox 19+ */
  color: #A8A8BE !important;
}

input:-ms-input-placeholder {
  color: #A8A8BE !important;
}

input::placeholder {
  color: #A8A8BE !important;
}
</style>
