<template>
  <div class="ats-form-battery">
    <AtsFormInputText
      :stateKey="name"
      commit="updateSection3AuxBatteryName"
      placeholder="Reservtoiteaku nimi"
      :useErrorKey="`aux_batteries.${this.name}.name`"
      textarea
    />
    <div class="ats-form-battery-flex">
      <div class="ats-form-battery__input-container">
        <input
          v-model="modelAh"
          type="number"
          class="tw-color-main tw-color-main-placeholder ats-form-battery__input"
          :disabled="isConfirmed"
        />
        <span>{{ "Ah" }}</span>
      </div>
      <AtsFormErrors :errorKey="`aux_batteries.${this.name}.amp_hour`" />
    </div>
    <div class="ats-form-battery-flex">
      <div class="ats-form-battery__input-container">
        <input
          v-model="modelV"
          type="number"
          class="tw-color-main tw-color-main-placeholder ats-form-battery__input"
          :disabled="isConfirmed"
        />
        <span>{{ "V" }}</span>
      </div>
      <AtsFormErrors :errorKey="`aux_batteries.${this.name}.volt`" />
    </div>
    <AtsFormButtonDelete :name="name" mobile @click="deleteBattery(name)" />
    <AtsFormButtonDelete :name="name" @click="deleteBattery(name)" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AtsFormInputText from "@/components/AtsFormInputText.vue";
import AtsFormErrors from "@/components/AtsFormErrors.vue";
import AtsFormButtonDelete from "@/components/AtsFormButtonDelete.vue";

export default {
  components: {
    AtsFormInputText,
    AtsFormErrors,
    AtsFormButtonDelete,
  },
  props: {
    name: Number,
  },
  computed: {
    ...mapGetters("atsForm", [
      "section3AuxBattery",
      "section3AuxBatteryAmp",
      "section3AuxBatteryVolt",
      "isConfirmed",
    ]),
    battery() {
      return this.section3AuxBattery(this.name);
    },
    text() {
      return this.battery.name;
    },
    modelAh: {
      get() {
        return this.battery.amp_hour;
      },
      set(value) {
        this.$store.commit("atsForm/updateSection3AuxBatteryAmpHours", {
          key: this.name,
          value,
        });
      },
    },
    modelV: {
      get() {
        return this.battery.volt;
      },
      set(value) {
        this.$store.commit("atsForm/updateSection3AuxBatteryVolt", {
          key: this.name,
          value,
        });
      },
    },
  },
  methods: {
    ...mapMutations("atsForm", ["deleteBattery"]),
  },
};
</script>

<style lang="scss">
.ats-form-battery {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  gap: 8px;
  justify-content: space-between;
}

.ats-form-battery__input-container {
  align-items: center;
  background-color: #e4e8ef;
  border-radius: 4px !important;
  border: 1px solid #d4d9e2;
  display: flex;
  flex: 0 1;
  gap: 8px;
  justify-content: space-between;
  max-height: 49px !important;
  padding: 14px;
}

.ats-form-battery__input {
  align-items: center;
  background-color: #e4e8ef;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-height: 49px !important;
  outline-width: 0px !important;
  max-width: 78px;
}

.ats-form-battery__input::-webkit-outer-spin-button,
.ats-form-battery__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ats-form-battery__input[type="number"] {
  -moz-appearance: textfield;
}

.tw-input::placeholder {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 15px !important;
}

.ats-form-battery-flex {
  flex: 1 1;
}

@media (min-width: 768px) {
  .ats-form-battery {
    max-width: 47%;
    flex-wrap: nowrap;
  }
}
</style>
