import { render, staticRenderFns } from "./AppLoader.vue?vue&type=template&id=0057deb0&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports