<template>
  <div class="d-flex flex-column flex-wrap" ref="signatureWrapper">
    <canvas ref="canvas" class="ats-form-signature" :width="padWidth"></canvas>
    <div v-if="!isConfirmed" class="tw-signature-buttons">
      <AtsFormErrors :errorKey="stateKey" />
      <button
        class="tw-text-15-bold tw-color-main tw-log-border-d4d9e2 tw-signature-buttons__button"
        @click="clear"
      >
        {{ $t("log.btn.clear") }}
      </button>
    </div>
  </div>
</template>

<script>
import SignaturePad from "signature_pad";
import { mapGetters } from "vuex";
import { debounce } from "lodash-es";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    AtsFormErrors,
  },
  props: {
    stateKey: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      commit: "updateSignatures",
      signaturepad: undefined,
      padWidth: 200,
    };
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
  },
  methods: {
    clear() {
      this.signaturepad.clear();
      this.$store.commit(`atsForm/${this.commit}`, {
        key: this.stateKey,
        value: "",
      });
    },
    save() {
      this.$store.commit(`atsForm/${this.commit}`, {
        key: this.stateKey,
        value: this.signaturepad.toDataURL(),
      });
    },
    resizeSignaturePad() {
      this.padWidth = this.$refs.signatureWrapper.offsetWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.resizeSignaturePad);
  },
  mounted() {
    const canvas = this.$refs.canvas;

    this.signaturepad = new SignaturePad(canvas);

    this.signaturepad.addEventListener(
      "afterUpdateStroke",
      debounce(() => {
        this.save();
      }, 50)
    );

    const signature = this.storeKeyValue(this.commit, this.stateKey);
    if (signature) {
      this.signaturepad.fromDataURL(signature);
    }

    if (this.isConfirmed) {
      this.signaturepad.off();
    }

    this.padWidth = this.$refs.signatureWrapper.offsetWidth;
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeSignaturePad);
  },
};
</script>

<style lang="scss" scoped>
.ats-form-signature {
  background-color: #e4e8ef !important;
  border-radius: 4px !important;
  border: 4px solid #e4e8ef;
  flex: 2;
}

.tw-signature-buttons {
  border-radius: 4px !important;
  display: flex;
  gap: 18px !important;
  justify-content: flex-end;
  margin-top: 8px !important;
  align-items: center;
}

.tw-signature-buttons__button {
  background-color: #fbfbfb !important;
  border-radius: 4px !important;
  padding: 7px 31px !important;
}
</style>
