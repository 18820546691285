<template>
  <div class="ats-log-list-button-back">
    <router-link
      class="btn btn-primary tw-button tw-text-15-bold tw-color-main"
      :to="{ name: 'ProductInfo' }"
    >
      {{ $t("common.back") }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    tenantId: String,
    uuid: String,
  },
};
</script>

<style lang="scss">
.ats-log-list-button-back {
  display: flex;
  margin-top: 42px;
}

.ats-log-list-button-back > * {
  background: #fbfbfb !important;
  flex: 0 1 172px;
}
</style>
