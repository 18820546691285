import Vue from "vue";
import Vuex from "vuex";
import { connection } from "@/api/connection";
import atsForm from "@/store/modules/atsForm.js";
import i18n from "@/store/modules/i18n.js";
import process from "@/store/modules/process.js";
import Cookies from "js-cookie";
import { parseInt } from "lodash-es";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showAdminArea: false,
    authorized: false,
    adminUser: undefined,
    logListState: {
      selectedEntryId: undefined,
    },
  },
  getters: {
    authorized: (state) => {
      return state.authorized;
    },
    adminUser: (state) => {
      return state.adminUser;
    }
  },
  mutations: {
    SET_SHOW_ADMIN_AREA(state, payload) {
      if (payload.key === process.env.VUE_APP_COOKIE_KEY) {
        state.showAdminArea = !!payload.newValue;
      }
    },
    rootSetAuthorized(state, payload) {
      state.authorized = payload;
    },
    rootSetMe(state, payload) {
      state.adminUser = payload;
    },
    rootSetSelectedEntryId(state, payload) {
      state.logListState.selectedEntryId =
        payload === undefined ? undefined : parseInt(payload);
    },
  },
  actions: {
    authenticate({ commit }) {
      const cookieToken = Cookies.get(process.env.VUE_APP_COOKIE_KEY, {
        domain: process.env.VUE_APP_COOKIE_DOMAIN,
      });

      commit("SET_SHOW_ADMIN_AREA", {
        key: process.env.VUE_APP_COOKIE_KEY,
        newValue: cookieToken,
      });

      connection
        .post("auth/me")
        .then((res) => {
          commit("rootSetAuthorized", true);
          commit("rootSetMe", res.data.data);
        })
        .catch(() => {
          commit("rootSetAuthorized", false);
        });
    },
  },
  modules: {
    atsForm,
    i18n,
    process,
  },
});
