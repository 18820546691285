import i18n, { availableLocales } from "@/i18n/i18n";
import { countryCodeToLocale, i18nSelectedLang } from "../../i18n/i18n";

const currentLocale = i18n.i18next.languages[0];

export default {
  namespaced: true,

  state: {
    availableLocales,
    requiredLocales: ["en"], //at least this field must be filled (names, title)
    currentLocale,
  },

  getters: {
    isLocaleRequired: (state) => (lang) => {
      return state.requiredLocales.indexOf(lang) > -1 ? true : false;
    },

    langsKeysDict: (state) => () => {
      var res = {};

      for (const lang in state.availableLocales) {
        res[lang] = null;
      }

      return res;
    },
  },

  actions: {
    checkStorage({ state, commit }) {
      const storedLocale = localStorage.getItem("locale");
      if (storedLocale && state.availableLocales[storedLocale]) {
        commit("SET_LOCALE", storedLocale);
      }
    },
    setLocale({ state, commit }, locale) {
      if (locale !== state.currentLocale) {
        i18n.i18next.changeLanguage(locale);
        commit("SET_LOCALE", locale);
      }
    },
    setLocaleGeo({ state, commit }, countryCode) {
      const locale = countryCodeToLocale[countryCode] || i18nSelectedLang;
      if (locale !== state.currentLocale) {
        commit("SET_LOCALE", locale);
      }
    },
  },

  mutations: {
    SET_LOCALE(state, locale) {
      i18n.i18next.changeLanguage(locale);
      state.currentLocale = locale;
      localStorage.setItem("locale", locale);
    },
  },
};
