import i18n from "@/i18n/i18n";

export function jLang(name_json, na_text) {
  let na = na_text !== undefined ? na_text : "";
  return name_json
    ? name_json[i18n.i18next.language] || name_json.en || na
    : na;
}

export function prettyBytes(num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? "-" : "") + num + " B";
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? "-" : "") + num + " " + unit;
}

export function nl2br(txt) {
  return String(txt).replace(/\n/g, "<br>");
}

export function isFileVideo(file) {
  return file.mime_type.startsWith("video/");
}

export function isFileImage(file) {
  return file.mime_type.startsWith("image/");
}

export function isFilePdf(file) {
  return file.mime_type.startsWith("application/pdf");
}
