<template>
  <div v-if="ats && ats.confirmed_at == null">
    <div
        class="d-flex justify-content-end tw-mt-18"
    >
      <LogEditButtonReport class="d-md-none" @click="store('openAts')" />
    </div>
  </div>
  <div v-else class="log-list-ats-link">
    <span v-if="span" class="log-list-ats-link__span"></span>
    <a
      :href="ats.url"
      target="_blank"
      class="tw-text-15 tw-color-main tw-leading-20 log-list-ats-link__link"
    >
      {{ ats.name }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    ats: Object,
    span: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.log-list-ats-link {
  display: flex;
}

.log-list-ats-link__span {
  display: none;
}

.log-list-ats-link__link {
  border-radius: 4px;
  border: #e4e8ef solid 1px;
  width: 100%;
  padding: 6px 8px !important;
}

@media screen and (min-width: 768px) {
  .log-list-ats-link__span {
    display: block;
    flex: 0 0 124px;
  }

  .log-list-ats-link__link {
    width: min-content;
  }
}
</style>
