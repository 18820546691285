import {
  BootstrapVue,
  BootstrapVueIcons,
  ModalPlugin,
  ToastPlugin,
} from "bootstrap-vue";
import Vue from "vue";
import Nl2br from "vue-nl2br";
import App from "./App";
import { isFileImage, isFilePdf, isFileVideo, jLang } from "./filters/filters";
import i18n from "./i18n/i18n";
import router from "./router/router";
import store from "./store/store";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.component("nl2br", Nl2br);

Vue.filter("jLang", jLang);
Vue.filter("isFileVideo", isFileVideo);
Vue.filter("isFileImage", isFileImage);
Vue.filter("isFilePdf", isFilePdf);

Vue.config.productionTip = false;

const app = new Vue({
  el: "#app",
  router,
  store,
  i18n,
  components: { App },
  render: (h) => h(App),
});

export { app };
