<template>
  <div
    class="ats-form-divider"
    :class="{ 'ats-form-divider--mobile-only': mobileOnly }"
  ></div>
</template>

<script>
export default {
  props: {
    mobileOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.ats-form-divider {
  background-color: #e4e8ef !important;
  height: 1px !important;
  width: 100% !important;
}

@media screen and (min-width: 640px) {
  .ats-form-divider--mobile-only {
    display: none !important;
  }
}
</style>
