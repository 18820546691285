import i18n from "@/i18n/i18n";

export default {
  namespaced: true,
  state: {
    tenant_name: undefined,
    process: undefined,
    process_name: undefined,
    step: {
      type: undefined,
      name: undefined,
      id: undefined,
    },
  },
  getters: {},
  mutations: {
    updateTenantName(state, value) {
      state.tenant_name = value;
    },
    updateProcess(state, payload) {
      if (payload) {
        state.process = payload.id;
        state.process_name = payload.name;
      } else {
        state.process = undefined;
        state.process_name = undefined;
      }
    },
    updateStep(state, value) {
      state.step = value;
    },
  },
  actions: {
    openProductWithQr({ commit, state }, {product_code, product_name}) {
      window.gtag &&
      window.gtag(
        "event",
        `${state.tenant_name}, opened product ${product_name} (${product_code}) with QR code (LANG: ${i18n.i18next.language})`,
        {
          event_category: state.tenant_name,
          event_label: "Product open",
          value: 1
        }
      )
    },
    openProduct({ commit, state }, {product_code, product_name}) {
      window.gtag &&
        window.gtag(
          "event",
          `${state.tenant_name}, opened product ${product_name} (${product_code}) (LANG: ${i18n.i18next.language})`,
          {
            event_category: state.tenant_name,
            event_label: "Product open",
            value: 1
          }
        )
    },
    openProcess({ commit, state }, { id, name }) {
      commit("updateProcess", { id, name });

      window.gtag &&
        window.gtag(
          "event",
          `${state.tenant_name}, opened process ${state.process_name} (LANG: ${i18n.i18next.language})`,
          {
            event_category: state.tenant_name,
            event_label: "Process open",
            value: 1,
          }
        );
    },
    closeProcess({ commit }) {
      commit("updateProcess", undefined);
      commit("updateStep", {
        type: undefined,
        name: undefined,
        id: undefined,
      });
    },
    pressStep({ commit, state }, step) {
      commit("updateStep", step);

      window.gtag &&
        window.gtag(
          "event",
          `${state.tenant_name}, clicked process ${state.process_name} step ${step.name} (LANG: ${i18n.i18next.language}) (PRODUCT CODE: ${step.product_code})`,
          {
            event_category: state.tenant_name,
            event_label: "Click process step",
            value: 1,
          }
        );
    },
    pressFileLink({ state }, {name, code}) {
      window.gtag &&
        window.gtag("event", `${state.tenant_name}, opened file ${name} (LANG: ${i18n.i18next.language}) (PRODUCT CODE: ${code.code})`, {
          event_category: state.tenant_name,
          event_label: "Open product file",
          value: 1,
        });
    },
    pressSendProblem({ state }, code) {
      window.gtag &&
        window.gtag(
          "event",
          `${state.tenant_name}, sent process ${state.process_name} email (LANG: ${i18n.i18next.language}) (PRODUCT CODE: ${code})`,
          {
            event_category: state.tenant_name,
            event_label: "Send message",
            value: 1,
          }
        );
    },
  },
};
