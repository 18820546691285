<template>
  <div v-if="section3AuxBatteries.length" class="ats-form-batteries">
    <AtsFormSectionBattery
      v-for="(auxBattery, key) of section3AuxBatteries"
      :key="`aux_battery_${key}`"
      :name="key"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AtsFormSectionBattery from "@/components/AtsFormBattery.vue";

export default {
  components: {
    AtsFormSectionBattery,
  },
  computed: {
    ...mapGetters("atsForm", ["section3AuxBatteries"]),
  },
};
</script>

<style lang="scss">
.ats-form-batteries {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
}
</style>
