import { API_ENDPOINT, connection } from "../connection.js";
import axios from "axios";

export default {
  async getForm(formId, productUUID) {
    return connection.get(
      `forms/get/${formId}?filterShowInEditor&product=${productUUID}`
    );
  },

  async getResponse(responseId) {
    return connection.get(`forms/response/${responseId}?withMeta`);
  },

  async saveForm(formId, entryId, data, responseId = null) {
    if (responseId === null || responseId === -1) {
      return connection.post(
        `forms/response/${formId}?entryId=${entryId}`,
        data
      );
    } else {
      return connection.post(
        `forms/response/${formId}/${responseId}?entryId=${entryId}`,
        data
      );
    }
  },

  async saveFile(formResponseId, fieldId, data) {
    const formData = new FormData();

    formData.append("file", data);

    return axios.post(
      `${API_ENDPOINT}/forms/response/${formResponseId}/upload/${fieldId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  async deleteFile(formResponseId, formFileId) {
    return axios.delete(`${API_ENDPOINT}/forms/response/${formResponseId}/upload/${formFileId}`);
  }
};
