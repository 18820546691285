import {API_ENDPOINT, connection} from "../connection.js";
import axios from "axios";

export default {
  async getPugLog(productId) {
    return connection.get("pub/log", {
      params: {
        product_id: productId,
      },
    });
  },
  async getPugLogType(logId) {
    return connection.get(`pub/log/${logId}/type`);
  },
  async getPugLogEntry(logId, query) {
    const response = await connection.get(`pub/log/${logId}/entry`, {
      params: query,
    });

    return response.data.data;
  },
  async storeLogEntry(data, logId, entryId) {
    if (entryId) {
      return connection.patch(`log/${logId}/entry/${entryId}`, { data });
    }

    return connection.post(`log/${logId}/entry`, { data });
  },
  async confirmLogEntry(data, logId, entryId) {
    return connection.patch(`log/${logId}/entry/${entryId}/confirm`, { data });
  },
  async get(logId, entryId) {
    return connection.get(`log/${logId}/entry/${entryId}/ats/get`);
  },
  async getPrefilledCustomer(logId) {
    return connection.get(`log/${logId}/ats/prefilled-customer`);
  },
  async getPrefilledDevices(logId) {
    return connection.get(`log/${logId}/ats/prefilled-devices`);
  },
  async save(logId, entryId, data) {
    return connection.post(`log/${logId}/entry/${entryId}/ats/save`, data);
  },
  async validate(logId, entryId, data) {
    return connection.post(`log/${logId}/entry/${entryId}/ats/validate`, data);
  },
  async isValidated(logId, entryId, data) {
    return connection.post(
      `log/${logId}/entry/${entryId}/ats/is-validated`,
      data
    );
  },
  async getForms(tenant_id) {
    return connection.get(`forms/all?without_pagination=true&tenant_id=${tenant_id}`);
  },

  async uploadFile(logId, entryId, data) {
    const formData = new FormData();

    formData.append("file", data);

    return axios.post(
      `log/${logId}/entry/${entryId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
};
