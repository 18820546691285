<template>
  <BFormSelect
    class="tw-log-border-d4d9e2 tw-text-15 select-log-count select-log-count--right"
    v-model="model"
    :options="options"
  />
</template>

<script>
import { BFormSelect } from "bootstrap-vue";

export default {
  components: {
    BFormSelect,
  },
  props: ["value"],
  data() {
    return {
      options: [
        { value: 5, text: "Kandeid lehel 5" },
        { value: 10, text: "Kandeid lehel 10" },
        { value: 15, text: "Kandeid lehel 15" },
        { value: 20, text: "Kandeid lehel 20" },
        { value: 25, text: "Kandeid lehel 25" },
      ],
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.select-log-count {
  color: #446883 !important;
  height: 58px !important;
  padding: 19px 0 19px 24px !important;
}

.select-log-count--right {
  background-image: linear-gradient(to right, #ccc, #ccc),
    url("data:image/svg+xml,%3Csvg width='10' height='19' viewBox='0 0 10 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.41171 0.202545C5.36584 0.140065 5.30446 0.0889788 5.23285 0.0536867C5.16125 0.0183945 5.08157 -4.52548e-05 5.00068 -4.52619e-05C4.91979 -4.5269e-05 4.84011 0.0183945 4.76851 0.0536866C4.6969 0.0889788 4.63552 0.140065 4.58965 0.202545L0.0892945 6.29856C0.0372029 6.36887 0.00665574 6.45123 0.000970884 6.53668C-0.00471398 6.62213 0.0146809 6.7074 0.0570488 6.78324C0.0994167 6.85908 0.163139 6.92258 0.241289 6.96684C0.319439 7.0111 0.40903 7.03444 0.500327 7.0343L9.50103 7.0343C9.59212 7.03395 9.68138 7.01032 9.75921 6.96595C9.83704 6.92158 9.90051 6.85816 9.94278 6.78249C9.98505 6.70683 10.0045 6.62179 9.99911 6.53652C9.9937 6.45126 9.96361 6.36899 9.91206 6.29856L5.41171 0.202545Z' fill='%23446883'/%3E%3Cpath d='M4.58829 17.8318C4.63416 17.8942 4.69555 17.9453 4.76715 17.9806C4.83875 18.0159 4.91843 18.0343 4.99932 18.0343C5.08021 18.0343 5.15989 18.0159 5.23149 17.9806C5.3031 17.9453 5.36448 17.8942 5.41035 17.8318L9.91071 11.7357C9.9628 11.6654 9.99334 11.5831 9.99903 11.4976C10.0047 11.4122 9.98532 11.3269 9.94295 11.2511C9.90058 11.1752 9.83686 11.1117 9.75871 11.0675C9.68056 11.0232 9.59097 10.9999 9.49967 11L0.49897 11C0.407884 11.0004 0.318625 11.024 0.24079 11.0684C0.162955 11.1127 0.0994899 11.1761 0.0572196 11.2518C0.0149493 11.3275 -0.00452693 11.4125 0.000885468 11.4978C0.00629787 11.583 0.0363941 11.6653 0.0879376 11.7357L4.58829 17.8318Z' fill='%23446883'/%3E%3C/svg%3E%0A");
  background-position: right 56px center, right 23px center;
  background-size: 1px 58px, 12px;
  background-repeat: no-repeat;
}
</style>
